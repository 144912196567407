import React, { useRef, useState } from "react";
import { IUseNonconformity, TipoPagina } from "../../hooks/types";

import styles from "./Details.module.css";
import { Navigate } from "react-router-dom";
import { usePreviousRoute } from "../../../../hooks/navigation";
import {
  Button,
  Input,
  LinkButton,
  Textarea,
} from "../../../../components/Form";
import { Circle } from "../../../../components/Loading";
import { isValid } from "../../../../helpers/validations";
import { useForm } from "../../../../hooks/form";

interface Props {
  types: TipoPagina[];
  data: IUseNonconformity;
}

type ActionDetails = "approve" | "reprove";

export default function Details({ types, data }: Props) {
  const {
    data: { selectedOrder },
    methods: { approveAndReproveNonconformity },
    loadings: { approvingOrReprovingNonconformity },
  } = data;

  const modalReasonRef = useRef<HTMLDialogElement>(null);
  const [actionDetails, setActionDetails] = useState<ActionDetails | null>(
    null
  );
  const reason = useForm({ required: true });
  const qtdApproved = useForm({ required: true });

  const previousRoute = usePreviousRoute();

  if (selectedOrder === null) {
    return <Navigate to={previousRoute} />;
  }

  return (
    <>
      <div className="container">
        {!approvingOrReprovingNonconformity ? (
          <>
            <div className={styles.navContainer}>
              <LinkButton to={previousRoute} buttonStyle="backButton" />
              <h2>Detalhes Inconformidade</h2>
              <div />
            </div>
            <span className="separator" />
            <fieldset className="fieldset">
              <legend className="fieldsetLegend">Detalhes do Pedido</legend>
              <ul className={styles.orderDetailsList}>
                <li className={styles.orderDetailsList__item}>
                  <p className={styles.orderDetailsList__item__title}>Id Pedido</p>
                  <p className={styles.orderDetailsList__item__detail}>{selectedOrder?.idPedido}</p>
                </li>
                <li className={styles.orderDetailsList__item}>
                  <p className={styles.orderDetailsList__item__title}>Id Empresa</p>
                  <p className={styles.orderDetailsList__item__detail}>{selectedOrder?.idEmpresa}</p>
                </li>
                <li className={styles.orderDetailsList__item}>
                  <p className={styles.orderDetailsList__item__title}>Quantidade Total</p>
                  <p className={styles.orderDetailsList__item__detail}>{selectedOrder?.item.quantidadeItem}</p>
                </li>
                <li className={styles.orderDetailsList__item}>
                  <p className={styles.orderDetailsList__item__title}>Quantidade Não Conforme</p>
                  <p className={styles.orderDetailsList__item__detail}>{selectedOrder?.qtdProdutoInconforme}</p>
                </li>
                <li className={styles.orderDetailsList__item}>
                  <p className={styles.orderDetailsList__item__title}>Nº Lote</p>
                  <p className={styles.orderDetailsList__item__detail}>
                    {selectedOrder?.numeroLote ?? "Não informado"}
                  </p>
                </li>
                <li className={styles.orderDetailsList__item}>
                  <p className={styles.orderDetailsList__item__title}>Nº Nota Fiscal</p>
                  <p className={styles.orderDetailsList__item__detail}>
                    {selectedOrder?.numeroNotaFiscal ?? "Não informado"}
                  </p>
                </li>
                <li className={styles.orderDetailsList__item}>
                  <p className={styles.orderDetailsList__item__title}>Ação/Parecer</p>
                  <p className={styles.orderDetailsList__item__detail}>
                    {selectedOrder?.acaoParecer?.nome ?? "Não informado"}
                  </p>
                </li>
                <li className={styles.orderDetailsList__item}>
                  <p className={styles.orderDetailsList__item__title}>Cliente</p>
                  <p className={styles.orderDetailsList__item__detail}>{selectedOrder?.pedido.cliente.nomeFantasia}</p>
                </li>
                <li className={styles.orderDetailsList__item}>
                  <p className={styles.orderDetailsList__item__title}>Vendedor</p>
                  <p className={styles.orderDetailsList__item__detail}>{selectedOrder?.vendedor.nome}</p>
                </li>
                <li className={styles.orderDetailsList__item}>
                  <p className={styles.orderDetailsList__item__title}>Nome Produto</p>
                  <p className={styles.orderDetailsList__item__detail}>
                    {selectedOrder?.item.formulado.descricao || "Desconhecido"}
                  </p>
                </li>

                <li className={styles.orderDetailsList__item} data-field="motivo">
                  <p className={styles.orderDetailsList__item__title}>Motivo</p>
                  <p className={styles.orderDetailsList__item__detail}>{selectedOrder?.motivo}</p>
                </li>

                {selectedOrder?.observacao && (
                  <li className={styles.orderDetailsList__item} data-field="observacao">
                    <p className={styles.orderDetailsList__item__title}>Observação Reabertura</p>
                    <p className={styles.orderDetailsList__item__detail}>{selectedOrder?.observacao}</p>
                  </li>
                )}
              </ul>
            </fieldset>
            <fieldset className="fieldset" style={{ minHeight: "300px" }}>
              <legend className="fieldsetLegend">Imagens</legend>
              <ul className={styles.imageList}>
                {selectedOrder.naoConformidadeImagem.map((item) => (
                  <li key={item.id} className={styles.imageList__imageItem}>
                    <img
                      // src={
                      //   "https://s1.static.brasilescola.uol.com.br/be/conteudo/images/imagem-em-lente-convexa.jpg"
                      // }
                      src={item.urlSigned}
                      alt="Imagem de não conformidade"
                      className={styles.imageList__imageItem__image}
                      onClick={() => {
                        if (item.urlSigned) {
                          // window.open(
                          //   "https://s1.static.brasilescola.uol.com.br/be/conteudo/images/imagem-em-lente-convexa.jpg",
                          //   "_blank"
                          // );
                          // window.open(item.url, "_blank");
                        }
                      }}
                    />
                  </li>
                ))}
              </ul>
            </fieldset>
            {types.some((type) =>
              [TipoPagina.pendingManager, TipoPagina.reopened, TipoPagina.pendingQuality].includes(type)
            ) && (
              <div className={styles.buttonContainer}>
                <Button
                  variant="danger"
                  onClick={() => {
                    setActionDetails("reprove");
                    modalReasonRef.current?.show();
                  }}
                >
                  Reprovar
                </Button>
                <Button
                  onClick={() => {
                    setActionDetails("approve");
                    modalReasonRef.current?.show();
                  }}
                >
                  Aprovar
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
            <span className="loadingMessage">Processando requisição</span>
          </div>
        )}
      </div>
      <dialog
        className={`modal ${styles.modalReason}`}
        ref={modalReasonRef}
        onClose={() => {
          setActionDetails(null);
          reason.reset();
          qtdApproved.reset();
        }}
      >
        <div className="modalContent">
          {actionDetails === "approve" && (
            <div>
              <label htmlFor="qtdApproved" className="label">
                Quantidade Aprovada
              </label>
              <Input
                id="qtdApproved"
                placeholder="Digite a quantidade que deseja aprovar"
                className="cleanInputNumber"
                type="number"
                value={qtdApproved.value}
                onChange={qtdApproved.onChange}
                error={qtdApproved.error}
              />
            </div>
          )}
          <div>
            <label htmlFor="reason" className="label">
              {actionDetails === "approve" ? "Motivo Aprovação" : "Motivo Reprovação"}
            </label>
            <Textarea
              id="reason"
              className={styles.textareaReason}
              placeholder={`Digite o motivo para a ${
                actionDetails === "approve" ? "aprovação" : "reprovação"
              } desta inconformidade`}
              value={reason.value}
              error={reason.error}
              onChange={reason.onChange}
              onBlur={() => {}}
            ></Textarea>
          </div>
          <span className={styles.modalReason__separator} />
          <div className={styles.modalReason__buttonContainer}>
            <Button
              type="button"
              variant="danger"
              onClick={() => {
                modalReasonRef.current?.close();
              }}
            >
              Cancelar
            </Button>
            <Button
              type="button"
              onClick={() => {
                if ((actionDetails === "approve" && isValid(reason, qtdApproved)) || isValid(reason)) {
                  approveAndReproveNonconformity(actionDetails!!, reason.value, Number(qtdApproved.value));
                  modalReasonRef.current?.close();
                }
              }}
            >
              Concluir
            </Button>
          </div>
        </div>
      </dialog>
    </>
  );
}
