import React from "react";

import { Button } from "../../../components/Form";
import { Input } from "../../../components/Form";

import { useForm } from "../../../hooks/form";
import { useAuth } from "../../../hooks/contexts";

import { isValid } from "../../../helpers/validations";

import logo from "../../../assets/images/SIG-logo-transparent.png";

import styles from "./Login.module.css";

export function Login() {
  const email = useForm({ type: "email", required: true });
  const password = useForm({ type: "", required: true });

  const Auth = useAuth();

  async function handleSubmit(e) {
    e.preventDefault();
    if (isValid(email, password)) Auth.signIn(email.value, password.value);
  }

  return (
    <section className={styles.wrapper} aria-label="login">
      <div className={styles.logoContainer}>
        <img src={logo} alt="logo sig" width="200" className={styles.logo} />
      </div>
      <span className={styles.separator}></span>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.inputContainer}>
          <label htmlFor="" className={`label ${styles.label}`}>
            E-mail
          </label>
          <Input
            autoComplete="username"
            type="email"
            className={styles.input}
            value={email.value}
            onChange={email.onChange}
            onBlur={email.onBlur}
            error={email.error}
          />
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="" className={`label ${styles.label}`}>
            Senha
          </label>
          <Input
            autoComplete="current-password"
            type="password"
            className={styles.input}
            value={password.value}
            onChange={password.onChange}
            onBlur={password.onBlur}
            error={password.error}
          />
        </div>
        {Auth.loading ? (
          <Button variant="" className={styles.button} disabled>
            Carregando...
          </Button>
        ) : (
          <Button variant="" className={styles.button}>
            Entrar
          </Button>
        )}
      </form>
    </section>
  );
}
