import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { Button } from "../../../../components/Form";
import { ITableData } from "../../../../components/Data/XTable";
import { useNavigate } from "react-router-dom";
import { DownloadSimple, Eye } from "phosphor-react";
import { formatDate, formatMoney } from "../../../../helpers/format";
import { usePagination } from "../../../../hooks/pagination";
import dateUtils from "../../../../helpers/dateUtils";
import useAxiosInstance from "../../../../api/hooks/useAxiosInstance";
import { PremiacoesApi } from "../../../../api/constants";
import { downloadResponseFile } from "../../../../helpers/responses";

type ResponseEmpresa = {
  empresas: Empresa[];
  empresaPadrao: number;
};

type Form = {
  idPedido: string;
  empresas: ISelectOption<Empresa>[];
  cliente: ISelectOption<Cliente> | null;
  dataInicial: string;
  dataFinal: string;
};

type Empresa = {
  idEmpresa: number;
  idCidade: number;
  nomeEmpresa: string;
  cnpj: string;
  inscricaoEstadual: string;
  inscricaoMunicipal: string;
  endereco: string;
  numero: string;
  bairro: string;
  cep: string;
  telefone: string;
  complemento: string;
  nomeFantasia: string;
  situacao: string;
  razaoSocial: string;
  dataModificacao: string;
};

type Cliente = {
  cliente: {
    idCliente: number;
    bairro: string;
    telefone: string;
    cep: string;
    cadastroPessoal: string;
    idCidade: number;
    endereco: string;
    nomeFantasia: string;
    numero: string;
    razaoSocial: string;
    sexo: string;
    telefone1: string;
    telefone2: string;
    tipoCliente: string;
    descricao: string;
    situacao: string;
    dataManutenção: string;
    flagCliente: string;
    dataNascimento: any;
    estadoCivil: any;
    idRegiao: any;
    creditoBloqueado: any;
    observacaoLimite: any;
    limiteVencimento: any;
    valorLimiteCredito: number;
  };
  cidade: {
    idCidade: number;
    idPais: number;
    nomeCidade: string;
    uf: string;
    cep: string;
    cepSecundario: string;
    situacao: string;
    idIBGE: string;
    dataModificacao: string;
    latitude: number;
    longitude: number;
  };
};

type ResponseBuscarBaixasManuaisRealizadas = {
  total: number;
  baixas: BaixaManual[];
};

type BaixaManual = {
  idBaixa: number;
  dataBaixa: string;
  idPedido: number;
  idCliente: number;
  nomeCliente: string;
  idResponsavel: number;
  nomeResponsavel: string;
  totalBaixado: number;
  pagamentosBaixa: PagamentoBaixa[];
};

type PagamentoBaixa = {
  pagamento: Pagamento;
  premiacao: Premiacao;
};

type Pagamento = {
  id: number;
  valor: number;
  formaPagamento: string;
  cancelado: string;
  observacao: any;
};

type Premiacao = {
  id: number;
  pessoa: string;
  saldo: number;
};

export default function useBaixasManuaisRealizadas() {
  const axios = useAxiosInstance();
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const pagination = usePagination(10);

  const form = useForm<Form>({
    mode: "onChange",
  });

  const [baixaManualSelecionada, setBaixaManualSelecionada] = useState<BaixaManual | null>(null);

  const [empresaOptions, setEmpresaOptions] = useState<ISelectOption<Empresa>[]>([]);
  const [clienteOptions, setClienteOptions] = useState<ISelectOption<Cliente>[]>([]);
  const [baixasManuaisRealizadas, setBaixasManuaisRealizadas] = useState<BaixaManual[]>([]);

  const buscandoEmpresas = useLoading();
  const buscandoClientes = useLoading();
  const buscandoBaixasManuaisRealizadas = useLoading();
  const gerandoRelatorioBaixaRealizada = useLoading();

  const gerarRealtorioBaixaRealizada = useCallback(
    async (idBaixaRealizada: number) => {
      try {
        gerandoRelatorioBaixaRealizada.setLoading(true);

        const response = await axios(PremiacoesApi.Relatorios.GerarRelatorioBaixaManualRealizada.ApiInfo.url, {
          method: PremiacoesApi.Relatorios.GerarRelatorioBaixaManualRealizada.ApiInfo.method,
          params: { idBaixaManual: idBaixaRealizada },
          responseType: "arraybuffer",
        });

        if (response.status === 200) {
          downloadResponseFile({
            file: response.data,
            type: "application/pdf",
            fileName: `relatorio-baixa-manual (id-baixa: ${idBaixaRealizada}).pdf`,
          });
        } else {
          Modal.error(response.data.message, response.data.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        gerandoRelatorioBaixaRealizada.setLoading(false);
      }
    },
    [gerandoRelatorioBaixaRealizada, axios, Modal]
  );

  const baixasManuaisRelizadasTableData = useMemo<ITableData>(() => {
    const data = baixasManuaisRealizadas.map((baixa) => ({
      ...baixa,
      totalBaixadoStyled: <p style={{ textAlign: "center" }}>{formatMoney(baixa.totalBaixado)}</p>,
      detalhes: (
        <Button
          type="button"
          variant="edit"
          onClick={() => {
            setBaixaManualSelecionada(baixa);
            navigate("detalhes");
          }}
          style={{ minWidth: "100%", padding: "8.5px" }}
          data-option-button
        >
          <Eye weight="fill" />
        </Button>
      ),
      downloadReport: (
        <Button
          type="button"
          variant="edit"
          onClick={() => {
            gerarRealtorioBaixaRealizada(baixa.idBaixa);
          }}
          style={{ minWidth: "100%", padding: "8.5px" }}
          data-option-button
        >
          <DownloadSimple weight="fill" />
        </Button>
      ),
    }));

    return {
      columns: [
        {
          title: "Pedido",
          objectName: "idPedido",
          isSorted: true,
          style: { width: "10px" },
        },
        {
          title: "Cliente",
          objectName: "nomeCliente",
          isSorted: true,
        },
        {
          title: "Total Baixado",
          objectName: "totalBaixadoStyled",
          isSorted: true,
          style: { width: "200px", textAlign: "center" },
        },
        {
          title: "Data",
          objectName: "dataBaixa",
          isSorted: true,
          style: { width: "180px" },
          formatting: (valor) => formatDate(valor, "dd/MM/yyyy hh:mm:ss"),
        },
        {
          title: "",
          objectName: "downloadReport",
          isSorted: false,
          style: { width: "70px" },
        },
        {
          title: "",
          objectName: "detalhes",
          isSorted: false,
          style: { width: "70px" },
        },
      ],
      data,
    };
  }, [baixasManuaisRealizadas, gerarRealtorioBaixaRealizada, navigate]);

  const buscarEmpresas = useCallback(async () => {
    try {
      buscandoEmpresas.setLoading(true);
      const json = (await customFetch("/premiations/manager/searchEnterprises", {
        body: {
          descricao: "",
        },
      })) as DefaultFetchResponse<ResponseEmpresa>;
      if (json.status === 200) {
        const options = json.object.empresas.map((option) => {
          return {
            value: option,
            label: `${option.idEmpresa} | ${option.nomeEmpresa}`,
          };
        });

        setEmpresaOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      buscandoEmpresas.setLoading(false);
    }
  }, [Modal, buscandoEmpresas, customFetch]);

  const buscarClientes = useCallback(
    async (description = "") => {
      try {
        buscandoClientes.setLoading(true);
        const json = (await customFetch("/premiations/manager/searchClients", {
          body: {
            descricao: description,
          },
        })) as DefaultFetchResponse<Cliente[]>;
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: option,
              label: `${option.cliente.idCliente} | ${option.cliente.razaoSocial} | ${option.cidade.nomeCidade}`,
              customLabel: (
                <div style={{ fontSize: "0.875rem" }} key={`${option.cliente.idCliente}-div`}>
                  <p key={`${option.cliente.idCliente}-p1`}>
                    {option.cliente.idCliente} | {option.cliente.razaoSocial}
                  </p>
                  <p style={{ marginTop: "4px" }} key={`${option.cliente.idCliente}-p2`}>
                    <i key={`${option.cliente.idCliente}-p2i`}>{option.cidade.nomeCidade}</i>
                  </p>
                </div>
              ),
            };
          });

          setClienteOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        buscandoClientes.setLoading(false);
      }
    },
    [Modal, buscandoClientes, customFetch]
  );

  const buscarClientesDebounced = useDebounce((description = "") => {
    buscarClientes(description);
  });

  const buscarBaixasManuaisRealizadas = useCallback(
    async ({ idPedido, empresas, cliente, dataInicial, dataFinal }: Form, pagina = 0, contar = false) => {
      try {
        buscandoBaixasManuaisRealizadas.setLoading(true);
        const json = (await customFetch("/premiations/finance/buscarBaixasManuaisRealizadas", {
          body: {
            idPedido: idPedido || null,
            idEmpresas: empresas.map((emp) => emp.value.idEmpresa),
            idCliente: cliente?.value.cliente.idCliente || null,
            dataInicial: dateUtils(dataInicial).toStartDayISOString(),
            dataFinal: dateUtils(dataFinal).toEndDayIsoString(),
            tamanho: pagination.maxItems,
            pagina,
            contar,
          },
        })) as DefaultFetchResponse<ResponseBuscarBaixasManuaisRealizadas>;
        if (json.status === 200) {
          if (contar) {
            pagination.reset();
            pagination.setTotalRecords(json.object.total);
          }
          setBaixasManuaisRealizadas(json.object.baixas);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setBaixasManuaisRealizadas([]);
          pagination.reset();
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        buscandoBaixasManuaisRealizadas.setLoading(false);
      }
    },
    [Modal, buscandoBaixasManuaisRealizadas, customFetch, pagination]
  );

  const resetarDados = useCallback(() => {
    setBaixaManualSelecionada(null);
  }, []);

  useEffect(() => {
    buscarEmpresas();
    buscarClientes();
  }, []); // eslint-disable-line

  return {
    form,
    options: {
      empresas: {
        value: empresaOptions,
        loading: buscandoEmpresas.isLoading,
      },
      clientes: {
        value: clienteOptions,
        loading: buscandoClientes.isLoading,
        buscarDebounced: buscarClientesDebounced,
      },
    },
    buscarBaixasManuais: {
      tableData: baixasManuaisRelizadasTableData,
      buscar: buscarBaixasManuaisRealizadas,
      loading: buscandoBaixasManuaisRealizadas.isLoading,
      possuiDados: baixasManuaisRelizadasTableData.data.length > 0,
    },
    gerandoRelatorioBaixaRealizada: gerandoRelatorioBaixaRealizada.isLoading,
    pagination,
    resetarDados,
    baixaManualSelecionada,
  };
}

export type UseBaixasManuaisRealizadas = ReturnType<typeof useBaixasManuaisRealizadas>;
