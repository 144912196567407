import React from "react";
import { Route, Routes } from "react-router-dom";
import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../hooks/form";
import { usePagination } from "../../../../hooks/pagination";
import { NewPriceRange } from "./NewPriceRange/NewPriceRange";
import { PricesRangeList } from "./PricesRangeList/PricesRangeList";
import { UpdatePriceRange } from "./UpdatePriceRange/UpdatePriceRange";

export interface IPriceRange {
  id: number;
  precoMinimo: number;
  precoMaximo: number;
  comissaoValor: number;
  rgb: string;
  status: "A" | "I";
  dataManutencao: string;
  formulado: {
    idFormulado: number;
    descricao: string;
  };
  empresa: {
    idEmpresa: number;
    descricao: string;
  };
  tabela: {
    idTabela: number;
    descricao: string;
  };
}

export interface ITableFormulaEnterprise {
  id: number;
  descricao: string;
}

interface ISearchTotalPriceRangeRecordsResponse {
  response: number;
  message: string;
  status: number;
  object: {
    paginas: number;
    total: number;
  };
}

export interface ISearchPricesRangeResponse {
  response: number;
  message: string;
  status: number;
  object: IPriceRange[];
}

export interface ISearchTablesFormulasEnterprises {
  response: number;
  message: string;
  status: number;
  object: ITableFormulaEnterprise[];
}

export function CommissionsPricesRange() {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const pagination = usePagination();

  const [selectedPriceRange, setSelectedPriceRange] = React.useState<IPriceRange | null>(null);

  const searchedPriceRange = useForm({ type: "text", required: false });
  const [priceRangesList, setPriceRangesList] = React.useState<IPriceRange[]>([]);

  const searchingPriceRanges = useLoading();

  const [tableOptions, setTableOptions] = React.useState<{ label: string; value: ITableFormulaEnterprise }[]>([]);
  const [formulaOptions, setFormulaOptions] = React.useState<{ label: string; value: ITableFormulaEnterprise }[]>([]);

  const table = useSelect({ type: "single", required: false });
  const formula = useSelect({ type: "single", required: false });

  const searchingTableOptions = useLoading();
  const searchingFormulaOptions = useLoading();

  const clearPriceRangesFilter = React.useCallback(() => {
    searchedPriceRange.setValue("");
    table.reset();
    formula.reset();
  }, [formula, searchedPriceRange, table]);

  const searchTotalPriceRangeRecordsPromise = React.useCallback(
    (description: string = "", tableId = 0, formulaId = 0) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/commissions/searchTotalPriceRangeRecords", {
            body: {
              tamanho: pagination.maxItems,
              descricao: description,
              idTabela: tableId,
              idFormulado: formulaId,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchPriceRangesPromise = React.useCallback(
    (description: string = "", page: number = 0, tableId = 0, formulaId = 0) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = customFetch("/commissions/searchPriceRangesPaginated", {
            body: {
              descricao: description,
              pagina: page ? page - 1 : 0,
              tamanho: pagination.maxItems,
              idTabela: tableId,
              idFormulado: formulaId,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const researchPriceRanges = async (description = "", tableId = 0, formulaId = 0, page: number) => {
    try {
      searchingPriceRanges.setLoading(true);
      const json = (await searchPriceRangesPromise(
        description,
        page,
        tableId,
        formulaId
      )) as ISearchPricesRangeResponse;
      if (json.status === 200) {
        setPriceRangesList(json.object);
        window.scrollTo({ top: 0, behavior: "auto" });
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingPriceRanges.setLoading(false);
    }
  };

  const searchTotalPriceRangeRecordsAndPricesRange = React.useCallback(
    async (description: string = "", tableId = 0, formulaId = 0) => {
      if (description === "" && tableId === 0 && formulaId === 0) {
        clearPriceRangesFilter();
      }
      try {
        searchingPriceRanges.setLoading(true);
        const jsonPriceRanges = (await searchPriceRangesPromise(
          description,
          0,
          tableId,
          formulaId
        )) as ISearchPricesRangeResponse;
        if (jsonPriceRanges.status === 200) {
          const jsonRecords = (await searchTotalPriceRangeRecordsPromise(
            description,
            tableId,
            formulaId
          )) as ISearchTotalPriceRangeRecordsResponse;
          if (jsonRecords.status === 200) {
            setPriceRangesList(jsonPriceRanges.object);
            pagination.setTotalRecords(jsonRecords.object.total);
          } else if (jsonRecords.status === 500) {
            Modal.error(jsonRecords.message, jsonRecords.object);
          } else {
            setPriceRangesList([]);
          }
        } else if (jsonPriceRanges.status === 500) {
          Modal.error(jsonPriceRanges.message, jsonPriceRanges.object);
        } else {
          setPriceRangesList([]);
          pagination.reset();
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingPriceRanges.setLoading(false);
      }
    },
    [
      Modal,
      clearPriceRangesFilter,
      pagination,
      searchPriceRangesPromise,
      searchTotalPriceRangeRecordsPromise,
      searchingPriceRanges,
    ]
  );

  const searchTablesPromise = React.useCallback(
    (description: string = "", groupIds: number[] | null = null) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/commissions/searchTables", {
            body: {
              descricao: description,
              groupIds,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      }) as Promise<ISearchTablesFormulasEnterprises>;
    },
    [customFetch]
  );

  const searchFormulasPromise = React.useCallback(
    (description: string = "") => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/commissions/searchFormulas", {
            body: {
              descricao: description,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      }) as Promise<ISearchTablesFormulasEnterprises>;
    },
    [customFetch]
  );

  const searchEnterprisesPromise = React.useCallback(
    (description: string = "") => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/commissions/searchEnterprises", {
            body: {
              descricao: description,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      }) as Promise<ISearchTablesFormulasEnterprises>;
    },
    [customFetch]
  );

  const searchTables = React.useCallback(
    async (description: string = "", groupIds: number[] | null = null) => {
      try {
        searchingTableOptions.setLoading(true);
        const json = await searchTablesPromise(description, groupIds);
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.id} | ${option.descricao}`,
            value: option,
          }));
          setTableOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setTableOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingTableOptions.setLoading(false);
      }
    },
    [Modal, searchTablesPromise, searchingTableOptions]
  );

  const searchFormulas = React.useCallback(
    async (description: string = "") => {
      try {
        searchingFormulaOptions.setLoading(true);
        const json = await searchFormulasPromise(description);
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.id} | ${option.descricao}`,
            value: option,
          }));
          setFormulaOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setFormulaOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingFormulaOptions.setLoading(false);
      }
    },
    [Modal, searchFormulasPromise, searchingFormulaOptions]
  );

  const searchTablesDebounced = useDebounce(searchTables);
  const searchFormulasDebounced = useDebounce(searchFormulas);

  React.useEffect(() => {
    setSelectedPriceRange(null);
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PricesRangeList
            pagination={pagination}
            priceRangesList={{
              value: priceRangesList,
              setValue: setPriceRangesList,
            }}
            searchedPriceRange={searchedPriceRange as unknown as IUseForm}
            searchingPriceRanges={searchingPriceRanges}
            searchPriceRangesPromise={searchPriceRangesPromise}
            searchTotalPriceRangeRecordsAndPricesRange={searchTotalPriceRangeRecordsAndPricesRange}
            setSelectedPriceRange={setSelectedPriceRange}
            tables={{
              search: searchTables,
              searchDebounced: searchTablesDebounced,
              searching: searchingTableOptions.isLoading,
              options: tableOptions,
              table,
            }}
            formulas={{
              search: searchFormulas,
              searchDebounced: searchFormulasDebounced,
              searching: searchingFormulaOptions.isLoading,
              options: formulaOptions,
              formula,
            }}
          />
        }
      />
      <Route
        path="nova"
        element={
          <NewPriceRange
            searchTablesPromise={searchTablesPromise}
            searchFormulasPromise={searchFormulasPromise}
            searchEnterprisesPromise={searchEnterprisesPromise}
            searchTotalPriceRangeRecordsAndPricesRange={searchTotalPriceRangeRecordsAndPricesRange}
          />
        }
      />
      <Route
        path="atualizar"
        element={
          <UpdatePriceRange
            searchTablesPromise={searchTablesPromise}
            searchFormulasPromise={searchFormulasPromise}
            searchEnterprisesPromise={searchEnterprisesPromise}
            filters={{
              priceRange: searchedPriceRange.value,
              tableId: table.value?.value.id,
              formulaId: formula.value?.value.id,
            }}
            researchPriceRanges={(description, tableId, formulaId) =>
              researchPriceRanges(description, tableId, formulaId, pagination.currentPage)
            }
            selectedPriceRange={selectedPriceRange}
          />
        }
      />
    </Routes>
  );
}
