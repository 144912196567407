import React, { useCallback, useState } from "react";

import { useForm } from "react-hook-form";
import { LoginApi } from "../../../../api/constants";
import useFetch from "../../../../api/hooks/useFetch";
import { useModal } from "../../../../hooks/contexts";
import { ITableData } from "../../../../components/Data/XTable";
import { useMemo } from "react";
import { Button } from "../../../../components/Form";
import { useNavigate } from "react-router-dom";
import { Pencil } from "phosphor-react";
import { usePreviousRoute } from "../../../../hooks/navigation";

export type User = LoginApi.Usuario.BuscarUsuarios.Usuario;
export type UserDevice = LoginApi.DispositivoUsuario.BuscarDispositivosUsuario.Dispositivo;

type UserForm = {
  user: ISelectOption<User> | null;
};

type UserDeviceForm = {
  conta: string;
  senha: string;
  modelo: string;
  serie: string;
  observacao: string;
  status: ISelectOption<"A" | "I">;
};

export default function useUserDevices() {
  const Modal = useModal();
  const navigate = useNavigate();

  const previousRoute = usePreviousRoute();

  const userForm = useForm<UserForm>({
    mode: "onChange",
    defaultValues: {
      user: null,
    },
  });

  const userDeviceForm = useForm<UserDeviceForm>({
    mode: "onChange",
    defaultValues: {
      conta: "",
      senha: "",
      modelo: "",
      serie: "",
      observacao: "",
      status: {
        value: "A",
        label: "Ativo",
      },
    },
  });

  const statusOptions = [
    {
      value: "A",
      label: "Ativo",
    },
    {
      value: "I",
      label: "Inativo",
    },
  ];

  const [selectedUserDevice, setSelectedUserDevice] = useState<UserDevice | null>(null);

  const searchUsers = useFetch(LoginApi.Usuario.BuscarUsuarios.ApiInfo, {
    select(data) {
      return data.object.map((user) => ({
        label: `${user.idUsuario} | ${user.nome}`,
        value: user,
      }));
    },
  });

  const searchUserDevices = useFetch(LoginApi.DispositivoUsuario.BuscarDispositivosUsuario.ApiInfo, {
    select(data) {
      return data.object;
    },
  });

  const insertUpdateUserDevice = useFetch(LoginApi.DispositivoUsuario.InserirAtualizarDispositivoUsuario.ApiInfo, {
    async onSuccess(response) {
      userDeviceForm.reset();
      userForm.handleSubmit((data) => searchUserDevices.fetch({ idUsuario: data.user!.value.idUsuario }))();
      await Modal.success(response.message);
      navigate(previousRoute);
    },
    onError(response) {
      Modal.error(response.message);
    },
  });

  const populateUserDeviceData = useCallback(
    (userProfile: UserDevice) => {
      userDeviceForm.setValue("conta", userProfile.conta);
      userDeviceForm.setValue("senha", userProfile.senha);
      userDeviceForm.setValue("modelo", userProfile.modelo);
      userDeviceForm.setValue("serie", userProfile.serie);
      userDeviceForm.setValue("observacao", userProfile.observacao);
      userDeviceForm.setValue("status", {
        value: userProfile.status,
        label: userProfile.status === "A" ? "Ativo" : "Inativo",
      });
    },
    [userDeviceForm]
  );

  const userDevicesTableData = useMemo<ITableData>(() => {
    const data =
      searchUserDevices.data?.map((item) => ({
        ...item,
        edit: (
          <>
            <Button
              type="button"
              variant="edit"
              style={{
                margin: "auto",
                padding: "0.5rem 0.75rem",
                minWidth: "100%",
                width: "3.125rem",
              }}
              onClick={() => {
                setSelectedUserDevice(item);

                populateUserDeviceData(item);

                navigate("atualizar");
              }}
              data-option-button
            >
              <Pencil weight="fill" />
            </Button>
          </>
        ),
      })) ?? [];

    return {
      columns: [
        {
          title: "ID",
          objectName: "idDispositivo",
          isSorted: false,
          style: { width: "10px" },
        },
        {
          title: "Modelo",
          objectName: "modelo",
          isSorted: false,
        },
        {
          title: "Serie",
          objectName: "serie",
          isSorted: false,
        },
        {
          title: "Status",
          objectName: "status",
          isSorted: false,
          formatting: (value) => (value === "A" ? "Ativo" : "Inativo"),
          style: { width: "10px" },
        },
        {
          title: "",
          objectName: "edit",
          isSorted: false,
          style: { width: "10px" },
        },
      ],
      data: data,
    };
  }, [navigate, populateUserDeviceData, searchUserDevices.data]);

  return {
    userForm,
    userDeviceForm,
    statusOptions,
    userDevicesTableData,
    selectedUserDevice,
    setSelectedUserDevice,
    queries: {
      searchUsers,
      searchUserDevices,
      insertUpdateUserDevice,
      hasUserProfiles: searchUserDevices.data !== null && searchUserDevices.data.length > 0,
    },
  };
}

export type UseUserDevices = ReturnType<typeof useUserDevices>;
