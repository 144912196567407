import React from "react";
import { Button, Input, LinkButton, Select, Textarea } from "../../../../components/Form";
import { UseBaixaManual } from "../hooks/useBaixaManual";

import styles from "./RealizarBaixas.module.css";
import { Circle } from "../../../../components/Loading";
import { formatMoney } from "../../../../helpers/format";
import { usePreviousRoute } from "../../../../hooks/navigation";
import { Navigate } from "react-router-dom";
import useRealizarBaixas, { Pessoa } from "../hooks/useRealizarBaixas";
import { Controller } from "react-hook-form";

interface Props {
  data: UseBaixaManual;
}

function RealizarBaixas({ data }: Props) {
  const {
    formulario: { form, controlledFieldsPagamento, adicionarPagamento, removerPagamento },
    options: { formaPagamentoOptions, pessoaOptions },
    gerandoRelatorioBaixaRealizada,
    realizarBaixas,
    realizandoBaixaManual,
  } = useRealizarBaixas({
    dadosBaixaManual: data,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  const previousRoute = usePreviousRoute();

  const handleRemoveFile = (index: number) => {
    form.setValue(`pagamentos.${index}.file`, null);
  };

  if (!data.pedidoSelecionado.value) {
    return <Navigate to={previousRoute} />;
  }

  return (
    <div className="container">
      {!realizandoBaixaManual && !gerandoRelatorioBaixaRealizada ? (
        <>
          <div className={styles.navContainer}>
            <LinkButton to={previousRoute} buttonStyle="backButton" />
            <h4 className={styles.navContainer__title}>
              Pedido: {data.pedidoSelecionado.value?.idPedido} | {data.pedidoSelecionado.value?.cliente.nomeCliente}{" "}
            </h4>
            <span />
          </div>
          <span className="separator" />
          <div className={styles.pagamentosContainer}>
            {controlledFieldsPagamento.map((pagamento, index) => (
              <div className={styles.containerPremiacao} key={pagamento.id}>
                <div>
                  <p className={styles.tituloPartePremiacao}>Pagamento</p>
                  <div className={styles.containerPremiacao__formPagamento}>
                    <div>
                      <Controller
                        name={`pagamentos.${index}.pessoa`}
                        control={control}
                        rules={{
                          required: "Selecione uma pessoa/premiação",
                          validate: (pessoa) => {
                            if (controlledFieldsPagamento.length > 1 && pessoa?.label === "Todos")
                              return 'Não é possível selecionar "Todos" junto de outros pagamentos';
                            return true;
                          },
                        }}
                        render={({ field }) => (
                          <>
                            <label htmlFor={field.name} className="label">
                              Pessoa
                            </label>
                            <Select
                              {...field}
                              onChange={(value: ISelectOption<Pessoa> | null) => {
                                if (value && value.label === "Todos") {
                                  form.setValue(`pagamentos.${index}.valorPagamento`, value.value.valorSaldo);
                                } else {
                                  form.setValue(`pagamentos.${index}.valorPagamento`, 0);
                                }
                                field.onChange(value);
                              }}
                              options={pessoaOptions}
                              placeholder="Selecione uma pessoa para pagar"
                              error={errors.pagamentos?.[index]?.pessoa?.message}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div>
                      <Controller
                        name={`pagamentos.${index}.dataPagamento`}
                        control={control}
                        rules={{
                          required: "Preencha a data",
                        }}
                        render={({ field }) => (
                          <>
                            <label htmlFor={field.name} className="label">
                              Data Pagamento
                            </label>
                            <Input {...field} type="date" error={errors.pagamentos?.[index]?.dataPagamento?.message} />
                          </>
                        )}
                      />
                    </div>
                    <div>
                      <Controller
                        name={`pagamentos.${index}.valorPagamento`}
                        control={control}
                        rules={{
                          required: "Digite o valor de pagamento",
                          max: {
                            value: pagamento.pessoa?.value.valorSaldo || 0,
                            message: "O valor não pode ultrapassar o valor em aberto (saldo)",
                          },
                        }}
                        render={({ field }) => (
                          <>
                            <label htmlFor={field.name} className="label">
                              Valor Pagamento
                            </label>
                            <Input
                              {...field}
                              type="number"
                              className="cleanInputNumber"
                              placeholder="Digite o valor a pagar"
                              error={errors.pagamentos?.[index]?.valorPagamento?.message}
                              disabled={pagamento.pessoa?.label === "Todos"}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div>
                      <Controller
                        name={`pagamentos.${index}.formaPagamento`}
                        control={control}
                        rules={{
                          required: "Selecione uma forma de pagamento",
                        }}
                        render={({ field }) => (
                          <>
                            <label htmlFor={field.name} className="label">
                              Forma Pagamento
                            </label>
                            <Select
                              {...field}
                              placeholder="Selecione a forma de pagamento"
                              options={formaPagamentoOptions}
                              error={errors.pagamentos?.[index]?.formaPagamento?.message}
                              isClearable={false}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div className={styles.containerPremiacao__formPagamento__comprovante}>
                      <Controller
                        name={`pagamentos.${index}.file`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <label htmlFor={field.name} className="label">
                              Comprovante
                              <div className={styles.customFileInput}>
                                <input
                                  {...field}
                                  id={field.name}
                                  className={styles.customFileInput}
                                  value={undefined}
                                  onChange={(e) => {
                                    const file = e.target.files?.[0] || null;
                                    field.onChange(file);
                                  }}
                                  type="file"
                                  accept=".png, .jpg, .jpeg"
                                />
                                <div className={styles.customFileInput__clickableArea}>
                                  <span className={styles.fileName}>{field.value?.name || "Selecione um arquivo"}</span>
                                  {field.value && (
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveFile(index);
                                      }}
                                      className={styles.removeFileButton}
                                    >
                                      X
                                    </button>
                                  )}
                                </div>
                              </div>
                            </label>
                          </>
                        )}
                      />
                    </div>
                    <div className={styles.containerPremiacao__formPagamento__observacao}>
                      <Controller
                        name={`pagamentos.${index}.observacao`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <label htmlFor={field.name} className="label">
                              Observação
                            </label>
                            <Textarea
                              {...field}
                              className={styles.textAreaObservacao}
                              placeholder="Digite uma observação do pagamento"
                              error={errors.pagamentos?.[index]?.observacao?.message}
                              rows="4"
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <span className={styles.separadorVertical} />
                <div>
                  <p className={styles.tituloPartePremiacao}>Contas a Pagar</p>
                  <div className={styles.containerPremiacao__dadosPremiacao}>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      {pagamento.pessoa ? pagamento.pessoa?.label : "Nenhuma Pessoa Adicionada"}
                    </p>
                    <div className={styles.containerPremiacao__dadosPremiacao__valorContainer}>
                      <p>Valor a Baixar Operação (R$)</p>
                      <p>{formatMoney(pagamento.valorPagamento)}</p>
                    </div>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Dados da Conta
                    </p>
                    <div className={styles.containerPremiacao__dadosPremiacao__valorContainer}>
                      <p>Valor em Aberto</p>
                      <p>{formatMoney(pagamento.pessoa?.value.valorSaldo || 0)}</p>
                    </div>
                    <div className={styles.containerPremiacao__dadosPremiacao__valorContainer}>
                      <p>Valor Baixado</p>
                      <p>{formatMoney(pagamento.pessoa?.value.valorPago || 0)}</p>
                    </div>
                  </div>
                </div>
                {controlledFieldsPagamento.length > 1 && (
                  <>
                    <div className={styles.buttonRemoverPagamentoContainer}>
                      <span className="separator" />
                      <Button
                        variant="danger"
                        onClick={() => removerPagamento(index)}
                        className={styles.buttonRemoverPagamentoContainer__button}
                      >
                        Remover Pagamento
                      </Button>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              onClick={() => adicionarPagamento()}
              className={styles.buttonAdicionarPagamento}
              disabled={controlledFieldsPagamento[0].pessoa?.label === "Todos"}
            >
              Adicionar Pagamento
            </Button>
            <Button onClick={() => handleSubmit(realizarBaixas)()} className={styles.buttonConcluirBaixaManual}>
              Concluir Baixa Manual
            </Button>
          </div>
        </>
      ) : (
        <div className="loadingContainer" style={{ justifyContent: "center", height: "500px" }}>
          <Circle size={100} />
        </div>
      )}
    </div>
  );
}

export default RealizarBaixas;
