import React from "react";

interface CustomIconProps {
  src: string;
  size?: number;
  color?: string;
  className?: string;
}

const CustomIcon: React.FC<CustomIconProps> = ({ src, size = 24, color, className }) => {
  return (
    <img
      src={src}
      alt="icon"
      className={className}
      style={{
        width: "auto",
        height: size,
        filter: color ? `invert(1) sepia(1) saturate(5) hue-rotate(180deg)` : undefined,
      }}
    />
  );
};

export default CustomIcon;
