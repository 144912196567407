import React from "react";
import { Button, Input, LinkButton, Select, Textarea } from "../../../../components/Form";
import { usePreviousRoute } from "../../../../hooks/navigation";
import { UseUserDevices } from "../hooks/useUserDevices";
import { Navigate } from "react-router-dom";
import { Controller } from "react-hook-form";

import styles from "./InsertAndUpdate.module.css";
import { Circle } from "../../../../components/Loading";

interface Props {
  data: UseUserDevices;
  isUpdatePage?: boolean;
}

export default function InsertAndUpdate({ data, isUpdatePage = false }: Props) {
  const previousRoute = usePreviousRoute();

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = data.userDeviceForm;

  const status = watch("status");

  const user = data.userForm.watch("user");

  const { insertUpdateUserDevice } = data.queries;

  if (user === null || (isUpdatePage && data.selectedUserDevice === null)) return <Navigate to={previousRoute} />;

  return (
    <div className="container">
      {!insertUpdateUserDevice.isFetching ? (
        <>
          <nav className={styles.navContainer}>
            <LinkButton buttonStyle="backButton" to={previousRoute} />
            <h1>
              {user.value.idUsuario} | {user.value.nome}
            </h1>
          </nav>
          <span className="separator" />
          <div className={styles.formContainer}>
            <Controller
              name={`conta`}
              control={control}
              rules={{
                required: "Digite a conta do usuário no dispositivo",
              }}
              render={({ field }) => (
                <div>
                  <label htmlFor={field.name} className="label">
                    Conta
                  </label>
                  <Input
                    {...field}
                    placeholder="Digite a conta do usuário no dispositivo"
                    error={errors.conta?.message}
                  />
                </div>
              )}
            />
            <Controller
              name={`senha`}
              control={control}
              rules={{
                required: "Digite a senha do usuário no dispositivo",
              }}
              render={({ field }) => (
                <div>
                  <label htmlFor={field.name} className="label">
                    Senha
                  </label>
                  <Input
                    {...field}
                    placeholder="Digite a senha do usuário no dispositivo"
                    error={errors.senha?.message}
                  />
                </div>
              )}
            />
            <Controller
              name={`modelo`}
              control={control}
              rules={{
                required: "Digite o modelo do dispositivo",
              }}
              render={({ field }) => (
                <div>
                  <label htmlFor={field.name} className="label">
                    Modelo
                  </label>
                  <Input {...field} placeholder="Digite o modelo do dispositivo" error={errors.modelo?.message} />
                </div>
              )}
            />
            <Controller
              name={`serie`}
              control={control}
              rules={{
                required: "Digite o número de série do dispositivo",
              }}
              render={({ field }) => (
                <div>
                  <label htmlFor={field.name} className="label">
                    Nº Série
                  </label>
                  <Input
                    {...field}
                    placeholder="Digite o número de série do dispositivo"
                    error={errors.serie?.message}
                  />
                </div>
              )}
            />
            <Controller
              name={`observacao`}
              control={control}
              rules={{
                required: "Digite a observação",
              }}
              render={({ field }) => (
                <div className={styles.formContainer__observationField}>
                  <label htmlFor={field.name} className="label">
                    Observação
                  </label>
                  <Textarea
                    className={undefined}
                    {...field}
                    placeholder="Digite a observação"
                    error={errors.observacao?.message}
                  />
                </div>
              )}
            />
            {isUpdatePage && (
              <Controller
                name={`status`}
                control={control}
                render={({ field }) => (
                  <div className={styles.formContainer__statusField}>
                    <label htmlFor={field.name} className="label">
                      Status
                    </label>
                    <Select
                      {...field}
                      value={status}
                      options={data.statusOptions ?? []}
                      error={errors.status?.message}
                      isClearable={false}
                      isSearchable={false}
                    />
                  </div>
                )}
              />
            )}
          </div>
          <Button
            className={styles.buttonSaveData}
            onClick={() => {
              handleSubmit((formData) => {
                insertUpdateUserDevice.fetch({
                  idUsuario: user.value.idUsuario,
                  conta: formData.conta,
                  senha: formData.senha,
                  modelo: formData.modelo,
                  serie: formData.serie,
                  observacao: formData.observacao,
                  status: formData.status.value,
                  idDispositivo: data.selectedUserDevice ? data.selectedUserDevice.idDispositivo : 0,
                });
              })();
            }}
          >
            Salvar Dados
          </Button>
        </>
      ) : (
        <div className="loadingContainer" style={{ height: "500px", justifyContent: "center" }}>
          <Circle size={100} />
          <p className="loadingMessage">Salvando Dados</p>
        </div>
      )}
    </div>
  );
}
