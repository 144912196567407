import { useFieldArray, useForm } from "react-hook-form";
import { UseBaixaManual } from "./useBaixaManual";
import { useCallback, useMemo } from "react";
import { useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { usePreviousRoute } from "../../../../hooks/navigation";
import { useNavigate } from "react-router-dom";
import { PremiacoesApi } from "../../../../api/constants";
import useAxiosInstance from "../../../../api/hooks/useAxiosInstance";
import { downloadResponseFile } from "../../../../helpers/responses";

type Form = {
  pagamentos: Pagamento[];
};

type Pagamento = {
  pessoa: ISelectOption<Pessoa> | null;
  dataPagamento: string;
  valorPagamento: number;
  formaPagamento: ISelectOption<FormaPagamento> | null;
  observacao: string;
  file: File | null;
};

export type Pessoa = {
  descricao: string;
  idEmpresa: number;
  idPedido: number;
  idPremiacao: number;
  tipo: "P" | "B";
  valorOriginal: number;
  valorPago: number;
  valorSaldo: number;
};

type FormaPagamento = string;

interface Props {
  dadosBaixaManual: UseBaixaManual;
}

export default function useRealizarBaixas({ dadosBaixaManual }: Props) {
  const axios = useAxiosInstance();
  const Modal = useModal();
  const navigate = useNavigate();

  const previousRoute = usePreviousRoute();

  const formaPagamentoOptions = useMemo<ISelectOption<FormaPagamento>[]>(
    () => [
      {
        value: "Dinheiro",
        label: "Dinheiro",
      },
      {
        value: "Cheque",
        label: "Cheque",
      },
      {
        value: "Cartão",
        label: "Cartão",
      },
      {
        value: "Comercial",
        label: "Zeramento Autorizado Pelo Comercial",
      },
      {
        value: "Financeiro",
        label: "Zeramento Por Atraso no Pagamento",
      },
      {
        value: "FinanceiroZeramento",
        label: "Zeramento Autorizado Pelo Financeiro",
      },
      {
        value: "Controle",
        label: "Controle Interno",
      },
      {
        value: "Outro",
        label: "Outro",
      },
    ],
    []
  );

  const pessoaOptions = useMemo<ISelectOption<Pessoa>[]>(() => {
    const pedido = dadosBaixaManual.pedidoSelecionado.value;

    if (pedido === null) return [];

    const options = (pedido.premiacoes as unknown as Pessoa[]).map((option) => {
      return {
        value: option,
        label: option.descricao,
      };
    });

    options.unshift({
      value: {
        idPremiacao: 0,
        idEmpresa: 0,
        idPedido: 0,
        valorOriginal: pedido.premiacoes.reduce((last, crr) => last + crr.valorOriginal, 0),
        descricao: "",
        valorSaldo: pedido.premiacoes.reduce((last, crr) => last + crr.valorSaldo, 0),
        valorPago: pedido.premiacoes.reduce((last, crr) => last + crr.valorPago, 0),
        tipo: "P",
      },
      label: "Todos",
    });

    return options;
  }, [dadosBaixaManual.pedidoSelecionado.value]);

  const form = useForm<Form>({
    defaultValues: {
      pagamentos: [
        {
          dataPagamento: "",
          formaPagamento: formaPagamentoOptions[0],
          observacao: "",
          pessoa: null,
          valorPagamento: 0,
        },
      ],
    },
  });

  const arrayPagamentos = useFieldArray<Form>({
    control: form.control,
    name: "pagamentos",
  });

  const pagamentosWatched = form.watch("pagamentos");

  const controlledFieldsPagamento = arrayPagamentos.fields.map((field, index) => {
    return {
      ...field,
      ...pagamentosWatched[index],
    };
  });

  const realizandoBaixaManual = useLoading();
  const gerandoRelatorioBaixaRealizada = useLoading();

  const adicionarPagamento = useCallback(() => {
    arrayPagamentos.append({
      dataPagamento: "",
      formaPagamento: formaPagamentoOptions[0],
      observacao: "",
      pessoa: null,
      valorPagamento: 0,
      file: null,
    });
  }, [arrayPagamentos, formaPagamentoOptions]);

  const removerPagamento = useCallback(
    (index: number) => {
      arrayPagamentos.remove(index);
    },
    [arrayPagamentos]
  );

  const gerarRealtorioBaixaRealizada = useCallback(
    async (idBaixaRealizada: number) => {
      try {
        gerandoRelatorioBaixaRealizada.setLoading(true);

        const response = await axios(PremiacoesApi.Relatorios.GerarRelatorioBaixaManualRealizada.ApiInfo.url, {
          method: PremiacoesApi.Relatorios.GerarRelatorioBaixaManualRealizada.ApiInfo.method,
          params: { idBaixaManual: idBaixaRealizada },
          responseType: "arraybuffer",
        });

        if (response.status === 200) {
          downloadResponseFile({
            file: response.data,
            type: "application/pdf",
            fileName: `relatorio-baixa-manual (id-baixa: ${idBaixaRealizada}).pdf`,
          });
        } else {
          Modal.error(response.data.message, response.data.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        gerandoRelatorioBaixaRealizada.setLoading(false);
      }
    },
    [gerandoRelatorioBaixaRealizada, axios, Modal]
  );

  const realizarBaixas = useCallback(
    async ({ pagamentos }: Form) => {
      try {
        realizandoBaixaManual.setLoading(true);
        const formData = new FormData();

        if (pagamentos.length === 1 && pagamentos[0].pessoa?.label === "Todos") {
          const dadosPagamentoGeral: Pagamento = pagamentos[0];

          const pessoas = pessoaOptions.slice(1);

          pessoas.forEach((pessoa, index) => {
            formData.append(`filtro[${index}].idPremiacao`, pessoa.value.idPremiacao.toString());
            formData.append(`filtro[${index}].valorPagar`, pessoa.value.valorSaldo.toString());
            formData.append(`filtro[${index}].tipoPagamento`, dadosPagamentoGeral.formaPagamento?.value || "");
            formData.append(
              `filtro[${index}].dataPagamento`,
              new Date(dadosPagamentoGeral.dataPagamento).toISOString()
            );
            formData.append(`filtro[${index}].observacao`, dadosPagamentoGeral.observacao);
            formData.append(`filtro[${index}].file`, dadosPagamentoGeral.file || "");
          });
        } else {
          pagamentos.forEach((pagamento, index) => {
            formData.append(`filtro[${index}].idPremiacao`, pagamento.pessoa?.value.idPremiacao.toString() || "");
            formData.append(`filtro[${index}].valorPagar`, pagamento.valorPagamento.toString());
            formData.append(`filtro[${index}].tipoPagamento`, pagamento.formaPagamento?.value || "");
            formData.append(`filtro[${index}].dataPagamento`, new Date(pagamento.dataPagamento).toISOString());
            formData.append(`filtro[${index}].observacao`, pagamento.observacao);
            formData.append(`filtro[${index}].file`, pagamento.file || "");
          });
        }

        const json = await axios.post("/premiations/finance/realizarBaixaManual", formData);
        if (json.data.status === 200) {
          dadosBaixaManual.form.handleSubmit(dadosBaixaManual.buscarPedidosPremiacoes.buscar)();
          const confirm = await Modal.confirm("Baixa realizada com sucesso!\nDeseja gerar o comprovante?");

          if (confirm) {
            gerarRealtorioBaixaRealizada(json.data.object.idBaixaManual);
          }

          navigate(previousRoute);
        } else {
          Modal.error(json.data.message, json.data.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        realizandoBaixaManual.setLoading(false);
      }
    },
    [
      realizandoBaixaManual,
      axios,
      pessoaOptions,
      dadosBaixaManual.form,
      dadosBaixaManual.buscarPedidosPremiacoes.buscar,
      Modal,
      navigate,
      previousRoute,
      gerarRealtorioBaixaRealizada,
    ]
  );

  return {
    formulario: {
      form,
      controlledFieldsPagamento,
      adicionarPagamento,
      removerPagamento,
    },
    options: {
      pessoaOptions,
      formaPagamentoOptions,
    },
    gerarRealtorioBaixaRealizada,
    gerandoRelatorioBaixaRealizada: gerandoRelatorioBaixaRealizada.isLoading,
    realizarBaixas,
    realizandoBaixaManual: realizandoBaixaManual.isLoading,
  };
}

export type UseRealizarBaixas = ReturnType<typeof useRealizarBaixas>;
