import React from "react";

import { isValid } from "../../../../../helpers/validations";

import { useCustomFetch, useDebounce, useLoading } from "../../../../../hooks/async";
import { useChanges, useModal } from "../../../../../hooks/contexts";
import { useForm, useSelect, useDate } from "../../../../../hooks/form";

import { Button, Input, Select } from "../../../../../components/Form";

import { Circle } from "../../../../../components/Loading";

import styles from "./Table.module.css";

export function Table({ selectedPriceTable, researchPriceTables, filteredPriceTable }) {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const { setIsChangesDetected } = useChanges();
  const registeringPriceTable = useLoading();

  const isSearchingValidityTables = useLoading();

  const priceTable = useForm({ type: "", required: true });
  const interestApplied = useForm({ type: "number", numberRange: { min: 0, max: 100 }, required: true });
  const chargeFreight = useSelect({ type: "single", required: true });
  const includeFreight = useSelect({ type: "single", required: true });
  const baseDate = useDate({ type: "", required: true });
  const interestType = useSelect({ type: "single", required: true });
  const freightValue = useForm({
    type: "number",
    numberRange: { min: 0.01 },
    required: !!includeFreight?.value?.value,
  });
  const personType = useSelect({ type: "single", required: true });
  const marginPhysicalPerson = useForm({ type: "number", required: false });
  const marginLegalPerson = useForm({ type: "number", required: false });
  const daysUntilInterestCollection = useForm({ type: "number", numberRange: { min: 0 }, required: true });
  const commissionType = useSelect({ type: "single", required: true });
  const maximumDiscount = useForm({ type: "number", numberRange: { min: 0, max: 100 }, required: false });
  const defaultValidityTable = useSelect({ type: "single", required: true });
  const priceTableStatus = useSelect({ type: "single", required: true });
  const dueDate = useDate({ type: "", required: true });
  const actionAfterDueDate = useSelect({ type: "single", required: true });
  const group = useSelect({ type: "single", required: true });
  const useMarginNegotiation = useSelect({ type: "single", required: true });
  const chargeICMS = useSelect({ type: "single", required: true });
  const chargeInterestOnFreight = useSelect({ type: "single", required: true });
  const freightType = useSelect({ type: "single", required: true });
  const minimumDueDate = useDate({ type: "", required: true });

  const chargeFreightOptions = React.useMemo(() => {
    return [
      {
        value: true,
        label: "Sim",
      },
      {
        value: false,
        label: "Não",
      },
    ];
  }, []);

  const includeFreightOptions = React.useMemo(() => {
    return [
      {
        value: true,
        label: "Sim",
      },
      {
        value: false,
        label: "Não",
      },
    ];
  }, []);

  const interestTypeOptions = React.useMemo(() => {
    return [
      {
        value: "C",
        label: "Dias Corridos",
      },
      {
        value: "M",
        label: "Data Média",
      },
    ];
  }, []);

  const personTypeOptions = React.useMemo(() => {
    return [
      {
        value: "F",
        label: "Pessoa Física",
      },
      {
        value: "J",
        label: "Pessoa Jurídica",
      },
    ];
  }, []);

  const priceTableStatusOptions = React.useMemo(() => {
    return [
      {
        value: true,
        label: "Ativo",
      },
      {
        value: false,
        label: "Inativo",
      },
    ];
  }, []);

  const actionAfterDueDateOptions = React.useMemo(() => {
    return [
      {
        value: "B",
        label: "Bloquear Incondicionalmente",
      },
      {
        value: "C",
        label: "Enviar Para o Comercial",
      },
    ];
  }, []);

  const commissionTypeOptions = React.useMemo(() => {
    return [
      {
        value: "M",
        label: "Margem",
      },
      {
        value: "D",
        label: "Desconto",
      },
    ];
  }, []);

  const useMarginNegotiationOptions = React.useMemo(() => {
    return [
      { value: "S", label: "Sim" },
      { value: "N", label: "Não" },
    ];
  }, []);

  const chargeICMSOptions = React.useMemo(() => {
    return [
      { value: "S", label: "Sim" },
      { value: "N", label: "Não" },
    ];
  }, []);

  const chargeInterestOnFreightOptions = React.useMemo(() => {
    return [
      { value: "S", label: "Sim" },
      { value: "N", label: "Não" },
    ];
  }, []);

  const freightTypeOptions = React.useMemo(() => {
    return [
      { value: "T", label: "Transportadora" },
      { value: "C", label: "Calculado" },
    ];
  }, []);

  const [validityTableOptions, setValidityTableOptions] = React.useState([]);

  const [groupOptions, setGroupOptions] = React.useState([]);

  const clearForm = React.useCallback(() => {
    setIsChangesDetected(false);
  }, [setIsChangesDetected]);

  const searchGroupOptions = React.useCallback(
    async (description = "") => {
      try {
        const json = await customFetch("/products/searchGroups", {
          body: {
            descricao: description,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((group) => {
            return {
              value: group.idGrupo,
              label: group.descricaoGrupo,
            };
          });
          setGroupOptions(options);
        }
      } catch (error) {
        Modal.error(error);
      }
    },
    [Modal, customFetch]
  );

  const searchValidityTableOptions = React.useCallback(
    async (description = "") => {
      try {
        isSearchingValidityTables.setLoading(true);
        const json = await customFetch("/products/searchValidityTablesOfPriceTable", {
          body: {
            idTabela: selectedPriceTable.tabela.idTabela,
            descricao: description,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: Number(option.id),
              label: `${option.nome}`,
            };
          });
          const finalOptions = [
            {
              value: "default",
              label: "Manter Indefinido",
            },
            ...options,
          ];
          setValidityTableOptions(finalOptions);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setValidityTableOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        isSearchingValidityTables.setLoading(false);
      }
    },
    [Modal, customFetch, isSearchingValidityTables, selectedPriceTable]
  );

  const { current: searchValidityTablesDebounced } = React.useRef(
    useDebounce((value) => {
      searchValidityTableOptions(value);
    })
  );

  const updatePriceTable = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        registeringPriceTable.setLoading(true);
        const json = await customFetch("/products/registerAndUpdatePriceTable", {
          body: {
            idTabela: Number(selectedPriceTable.tabela.idTabela),
            descricao: priceTable.value,
            dataBase: baseDate.toISOString(),
            jurosTabela: Number(interestApplied.value),
            situacao: priceTableStatus.value.value,
            freteObrigatorio: chargeFreight.value.value,
            tipoPessoa: personType.value.value,
            tipoComissao: commissionType.value.value,
            descontoTabela: maximumDiscount.value ? Number(maximumDiscount.value) : 0,
            freteValor: Number(freightValue.value) || null,
            adicionarFrete: includeFreight.value.value,
            vigenciaPadrao: defaultValidityTable.value.value !== "default" ? defaultValidityTable.value.value : null,
            lucroJuridicaPadrao: Number(marginLegalPerson.value) || null,
            lucroFisicaPadrao: Number(marginPhysicalPerson.value) || null,
            tipoDeJuros: interestType.value.value,
            diasCobrarJuros: Number(daysUntilInterestCollection.value),
            vencimentoMaximo: dueDate.toISOString(),
            bloquearVencimento: actionAfterDueDate.value.value,
            idGrupo: group.value.value,
            usarNegociacaoMargem: useMarginNegotiation.value.value,
            cobrarICMS: chargeICMS.value.value,
            cobrarJurosFrete: chargeInterestOnFreight.value.value,
            tipoFrete: freightType.value.value,
            vencimentoMinimo: minimumDueDate.toISOString(),
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      } finally {
        registeringPriceTable.setLoading(false);
      }
    });
  }, [
    registeringPriceTable,
    customFetch,
    selectedPriceTable,
    priceTable,
    baseDate,
    interestApplied,
    priceTableStatus,
    chargeFreight,
    personType,
    commissionType,
    maximumDiscount,
    freightValue,
    includeFreight,
    defaultValidityTable,
    marginLegalPerson,
    marginPhysicalPerson,
    interestType,
    daysUntilInterestCollection,
    dueDate,
    actionAfterDueDate,
    group,
    useMarginNegotiation,
    chargeICMS,
    chargeInterestOnFreight,
    freightType,
    minimumDueDate,
  ]);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (
        isValid(
          priceTable,
          baseDate,
          baseDate,
          interestApplied,
          priceTableStatus,
          chargeFreight,
          personType,
          commissionType,
          maximumDiscount,
          freightValue,
          includeFreight,
          defaultValidityTable,
          marginLegalPerson,
          marginPhysicalPerson,
          interestType,
          daysUntilInterestCollection,
          dueDate,
          actionAfterDueDate,
          group,
          useMarginNegotiation,
          chargeICMS,
          chargeInterestOnFreight,
          freightType,
          minimumDueDate
        )
      ) {
        try {
          const json = await updatePriceTable();
          if (json.status === 200) {
            await Modal.success(json.message);
            clearForm();
            researchPriceTables(filteredPriceTable);
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        }
      }
    },
    [
      priceTable,
      baseDate,
      interestApplied,
      priceTableStatus,
      chargeFreight,
      personType,
      commissionType,
      maximumDiscount,
      freightValue,
      includeFreight,
      defaultValidityTable,
      marginLegalPerson,
      marginPhysicalPerson,
      interestType,
      daysUntilInterestCollection,
      updatePriceTable,
      Modal,
      clearForm,
      researchPriceTables,
      dueDate,
      actionAfterDueDate,
      filteredPriceTable,
      group,
      useMarginNegotiation,
      chargeICMS,
      chargeInterestOnFreight,
      freightType,
      minimumDueDate,
    ]
  );

  React.useEffect(() => {
    priceTable.setValue(selectedPriceTable.tabela.descricaoTabela);
    personType.setValue(() => {
      const index = personTypeOptions.findIndex((option) => option.value === selectedPriceTable.tabela.tipoPessoa);
      return personTypeOptions[index];
    });
    interestType.setValue(() => {
      const index = interestTypeOptions.findIndex((option) => option.value === selectedPriceTable.tabela.tipoDeJuros);
      return interestTypeOptions[index];
    });
    chargeFreight.setValue(
      selectedPriceTable.tabela.temFrete === "S" ? chargeFreightOptions[0] : chargeFreightOptions[1]
    );
    includeFreight.setValue(
      selectedPriceTable.tabela.adicionarFreteProduto === "S" ? includeFreightOptions[0] : includeFreightOptions[1]
    );
    marginPhysicalPerson.setValue(selectedPriceTable.tabela.lucroFisicaPadrao || "");
    marginLegalPerson.setValue(selectedPriceTable.tabela.lucroJuridicaPadrao || "");
    interestApplied.setValue(selectedPriceTable.tabela.jurosTabela || "");
    daysUntilInterestCollection.setValue(selectedPriceTable.tabela.diasCobrarJuros || "");
    dueDate.setValue(() => {
      const date = selectedPriceTable.tabela.vencimentoMaximo?.replace(/^(\d{4}-\d{2}-\d{2}).*$/, "$1") || "";
      return date;
    });
    actionAfterDueDate.setValue(
      selectedPriceTable.tabela.bloquearVencimento === "C" ? actionAfterDueDateOptions[1] : actionAfterDueDateOptions[0]
    );
    commissionType.setValue(() => {
      if (selectedPriceTable.tabela.tipoComissao === "M") return { value: "M", label: "Margem" };
      else if (selectedPriceTable.tabela.tipoComissao === "D") return { value: "D", label: "Desconto" };
      else
        return {
          value: selectedPriceTable.tabela.tipoComissao,
          label: `Não Informado`,
        };
    });
    maximumDiscount.setValue(selectedPriceTable.tabela.descontoMaximo || "");
    freightValue.setValue(selectedPriceTable.tabela.freteValor || "");
    baseDate.setValue(() => {
      const date = selectedPriceTable.tabela.dataBaseTabela?.replace(/^(\d{4}-\d{2}-\d{2}).*$/, "$1") || "";
      return date;
    });
    defaultValidityTable.setValue(() => {
      if (selectedPriceTable.tabelavigencia) {
        return {
          value: Number(selectedPriceTable.tabelavigencia.id),
          label: selectedPriceTable.tabelavigencia.nome,
        };
      } else {
        return {
          value: "default",
          label: "Manter Indefinido",
        };
      }
    });
    priceTableStatus.setValue(
      selectedPriceTable.tabela.situacaoTabela === "S" ? priceTableStatusOptions[0] : priceTableStatusOptions[1]
    );
    if (selectedPriceTable.tabela.idGrupo) {
      group.setValue({
        value: selectedPriceTable.tabela.idGrupo,
        label: selectedPriceTable.tabela.grupo.descricaoGrupo,
      });
    }
    useMarginNegotiation.setValue(() => {
      return selectedPriceTable.tabela.usarNegociacaoMargem === "S"
        ? useMarginNegotiationOptions[0]
        : useMarginNegotiationOptions[1];
    });
    chargeICMS.setValue(() => {
      return selectedPriceTable.tabela.cobrarICMS === "S" ? chargeICMSOptions[0] : chargeICMSOptions[1];
    });
    chargeInterestOnFreight.setValue(() => {
      return selectedPriceTable.tabela.cobrarJurosFrete === "S"
        ? chargeInterestOnFreightOptions[0]
        : chargeInterestOnFreightOptions[1];
    });
    freightType.setValue(() => {
      return selectedPriceTable.tabela.tipoFrete === "T" ? freightTypeOptions[0] : freightTypeOptions[1];
    });
    minimumDueDate.setValue(() => {
      const date = selectedPriceTable.tabela.vencimentoMinimo?.replace(/^(\d{4}-\d{2}-\d{2}).*$/, "$1") || "";
      return date;
    });
  }, []); // eslint-disable-line

  React.useEffect(() => {
    searchValidityTableOptions();
    searchGroupOptions();
  }, []); // eslint-disable-line

  return (
    <div className={``}>
      {!registeringPriceTable.isLoading ? (
        <>
          <div>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className={styles.fieldsContainer}>
                <div className={styles.fieldDiv} data-grid="description">
                  <label htmlFor="priceTable" className="label">
                    Descrição
                  </label>
                  <Input
                    id="priceTable"
                    placeholder="Digite o nome da tabela de preços"
                    value={priceTable.value}
                    error={priceTable.error}
                    onChange={(e) => {
                      priceTable.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={priceTable.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="group">
                  <label htmlFor="group" className="label">
                    Grupo Formulado
                  </label>
                  <Select
                    id="group"
                    placeholder="Selecione o grupo do formulado"
                    options={groupOptions}
                    value={group.value}
                    error={group.error}
                    onChange={(e) => {
                      group.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={group.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="personType">
                  <label htmlFor="personType" className="label">
                    Tipo de Pessoa
                  </label>
                  <Select
                    id="personType"
                    options={personTypeOptions}
                    value={personType.value}
                    error={personType.error}
                    onChange={(value) => {
                      personType.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={personType.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="interestType">
                  <label htmlFor="interestType" className="label">
                    Tipo de Juros
                  </label>
                  <Select
                    id="interestType"
                    options={interestTypeOptions}
                    value={interestType.value}
                    error={interestType.error}
                    onChange={(value) => {
                      interestType.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={interestType.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="chargeFreight">
                  <label htmlFor="chargeFreight" className="label">
                    Cobrar Frete
                  </label>
                  <Select
                    id="chargeFreight"
                    options={chargeFreightOptions}
                    value={chargeFreight.value}
                    error={chargeFreight.error}
                    onChange={(value) => {
                      chargeFreight.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={chargeFreight.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="includeFreight">
                  <label htmlFor="includeFreight" className="label">
                    Incluir Frete no Produto
                  </label>
                  <Select
                    id="includeFreight"
                    options={includeFreightOptions}
                    value={includeFreight.value}
                    error={includeFreight.error}
                    onChange={(value) => {
                      includeFreight.onChange(value);
                      if (!value.value) freightValue.reset();
                      setIsChangesDetected(true);
                    }}
                    onBlur={includeFreight.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="marginPhysicalPerson">
                  <label htmlFor="marginPhysicalPerson" className="label">
                    Lucro Sobre Pessoa Física (%) (Opcional)
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="marginPhysicalPerson"
                    placeholder="Digite a margem de lucro sobre pessoa física"
                    value={marginPhysicalPerson.value}
                    error={marginPhysicalPerson.error}
                    onChange={(e) => {
                      marginPhysicalPerson.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={marginPhysicalPerson.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="marginLegalPerson">
                  <label htmlFor="marginLegalPerson" className="label">
                    Lucro Sobre Pessoa Jurídica (%) (Opcional)
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="marginLegalPerson"
                    placeholder="Digite a margem de lucro sobre pessoa jurídica"
                    value={marginLegalPerson.value}
                    error={marginLegalPerson.error}
                    onChange={(e) => {
                      marginLegalPerson.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={marginLegalPerson.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="interestApplied">
                  <label htmlFor="interestApplied" className="label">
                    Juros Aplicado (%)
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="interestApplied"
                    placeholder="Digite a porcentagem de juros aplicado"
                    value={interestApplied.value}
                    error={interestApplied.error}
                    onChange={(e) => {
                      interestApplied.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={interestApplied.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="daysUntilInterestCollection">
                  <label htmlFor="daysUntilInterestCollection" className="label">
                    Dias Até a Cobrança de Juros
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="daysUntilInterestCollection"
                    placeholder="Digite em quantos dias os juros passarão a ser contados"
                    value={daysUntilInterestCollection.value}
                    error={daysUntilInterestCollection.error}
                    onChange={(e) => {
                      daysUntilInterestCollection.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={daysUntilInterestCollection.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="minimumDueDate">
                  <label htmlFor="minimumDueDate" className="label">
                    Vencimento Mínimo
                  </label>
                  <Input
                    id="minimumDueDate"
                    type="date"
                    value={minimumDueDate.value}
                    error={minimumDueDate.error}
                    onChange={(e) => {
                      minimumDueDate.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={minimumDueDate.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="dueDate">
                  <label htmlFor="dueDate" className="label">
                    Vencimento Máximo
                  </label>
                  <Input
                    id="dueDate"
                    type="date"
                    value={dueDate.value}
                    error={dueDate.error}
                    onChange={(e) => {
                      dueDate.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={dueDate.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="actionAfterDueDate">
                  <label htmlFor="actionAfterDueDate" className="label">
                    Ação Após Vencimento Máximo
                  </label>
                  <Select
                    id="actionAfterDueDate"
                    options={actionAfterDueDateOptions}
                    value={actionAfterDueDate.value}
                    error={actionAfterDueDate.error}
                    onChange={(value) => {
                      actionAfterDueDate.onChange(value);
                      if (!value.value) freightValue.reset();
                      setIsChangesDetected(true);
                    }}
                    onBlur={actionAfterDueDate.onBlur}
                    isSearchable={false}
                    isClearable={false}
                    isDisabled={!dueDate.value.length}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="commissionType">
                  <label htmlFor="commissionType" className="label">
                    Tipo de Comissão
                  </label>
                  <Select
                    id="commissionType"
                    options={commissionTypeOptions}
                    value={commissionType.value}
                    isClearable={false}
                    isSearchable={false}
                    isDisabled
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="maximumDiscount">
                  <label htmlFor="maximumDiscount" className="label">
                    Desconto ao Aplicar na Tabela (%)
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="maximumDiscount"
                    placeholder="Digite a porcentagem máxima de desconto"
                    value={maximumDiscount.value}
                    error={maximumDiscount.error}
                    onChange={(e) => {
                      maximumDiscount.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={maximumDiscount.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="freightValue">
                  <label htmlFor="freightValue" className="label">
                    Preço do Frete (R$)
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="freightValue"
                    placeholder="Digite o preço do frete"
                    value={freightValue.value}
                    error={freightValue.error}
                    onChange={(e) => {
                      freightValue.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={freightValue.onBlur}
                    disabled={!includeFreight?.value?.value}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="baseDate">
                  <label htmlFor="baseDate" className="label">
                    Data de Vigor
                  </label>
                  <Input
                    id="baseDate"
                    type="date"
                    value={baseDate.value}
                    error={baseDate.error}
                    onChange={(e) => {
                      baseDate.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={baseDate.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="useMarginNegotiation">
                  <label htmlFor="useMarginNegotiation" className="label">
                    Usar Negociação Margem
                  </label>
                  <Select
                    id="useMarginNegotiation"
                    options={useMarginNegotiationOptions}
                    value={useMarginNegotiation.value}
                    error={useMarginNegotiation.error}
                    onChange={(value) => {
                      useMarginNegotiation.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={useMarginNegotiation.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="chargeICMS">
                  <label htmlFor="chargeICMS" className="label">
                    Cobrar ICMS
                  </label>
                  <Select
                    id="chargeICMS"
                    options={chargeICMSOptions}
                    value={chargeICMS.value}
                    error={chargeICMS.error}
                    onChange={(value) => {
                      chargeICMS.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={chargeICMS.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="chargeInterestOnFreight">
                  <label htmlFor="chargeInterestOnFreight" className="label">
                    Cobrar Juros no Frete
                  </label>
                  <Select
                    id="chargeInterestOnFreight"
                    options={chargeInterestOnFreightOptions}
                    value={chargeInterestOnFreight.value}
                    error={chargeInterestOnFreight.error}
                    onChange={(value) => {
                      chargeInterestOnFreight.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={chargeInterestOnFreight.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="freightType">
                  <label htmlFor="freightType" className="label">
                    Tipo Frete
                  </label>
                  <Select
                    id="freightType"
                    options={freightTypeOptions}
                    value={freightType.value}
                    error={freightType.error}
                    onChange={(value) => {
                      freightType.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={freightType.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="defaultValidityTable">
                  <label htmlFor="defaultValidityTable" className="label">
                    Tabela de Vigência Padrão
                  </label>
                  <Select
                    options={validityTableOptions}
                    value={defaultValidityTable.value}
                    onChange={defaultValidityTable.onChange}
                    onInputChange={searchValidityTablesDebounced}
                    noOptionsMessage={() => "Nenhuma tabela de vigência encontrada"}
                    loadingMessage={() => "Buscando..."}
                    isLoading={isSearchingValidityTables.isLoading}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="priceTableStatus">
                  <label htmlFor="priceTableStatus" className="label">
                    Status da Tabela de Preços
                  </label>
                  <Select
                    options={priceTableStatusOptions}
                    value={priceTableStatus.value}
                    onChange={priceTableStatus.onChange}
                  />
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Atualizar Tabela de Preços</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando tabela de preços</span>
        </div>
      )}
    </div>
  );
}
