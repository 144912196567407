import { API } from "../../hooks/useFetch";

/**
 * @description Contém todas as definições de endpoints para a API NovaPremiacao
 */
namespace PremiacoesApi {}

/**
 * @description Contém todas as definições de endpoints para o grupo Gerente
 */
namespace PremiacoesApi.Gerente {
  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca os vendedores.
   *
   * @endpoint_api `/Gerente/consultar/vendedores`
   *
   * @enpoint `/premiations/manager/searchSellers`
   *
   * @method POST
   */
  export namespace BuscarVendedores {
    export interface Vendedor {
      idVendedor: number;
      nomeVendedor: string;
      idEmpresa: number;
      situacao: "A" | "I";
      dataManutencao: string;
      idSupervisor: number
    }

    export type ApiResponse = DefaultFetchResponse<Vendedor[]>;

    export type ApiParams = { descricao: string, idsVendedores?: number[] };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/premiations/manager/searchSellers",
      method: API.HttpMethod.POST,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca os clientes.
   *
   * @endpoint_api `/Gerente/consultar/clientes`
   *
   * @enpoint `/premiations/manager/searchClients`
   *
   * @method POST
   */
  export namespace BuscarClientes {
    export interface Root {
      cliente: Cliente
      cidade: Cidade
    }
    
    export interface Cliente {
      idCliente: number
      bairro: string
      telefone: string
      cep: string
      cadastroPessoal: string
      idCidade: number
      endereco: string
      nomeFantasia: string
      numero: string
      razaoSocial: string
      sexo: string
      telefone1: string
      telefone2: string
      tipoCliente: string
      descricao: string
      situacao: string
      dataManutenção: string
      flagCliente: string
      dataNascimento: any
      estadoCivil: any
      idRegiao: any
      creditoBloqueado: any
      observacaoLimite: any
      limiteVencimento: any
      valorLimiteCredito: number
    }
    
    export interface Cidade {
      idCidade: number
      idPais: number
      nomeCidade: string
      uf: string
      cep: string
      cepSecundario: string
      situacao: string
      idIBGE: string
      dataModificacao: string
      latitude: number
      longitude: number
    }

    export type ApiResponse = DefaultFetchResponse<Root[]>;

    export type ApiParams = { descricao: string, descricaoCidade?: string, idsClientes?: number[] };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/premiations/manager/searchClients",
      method: API.HttpMethod.POST,
    };
  }
}

/**
 * @description Contém todas as definições de endpoints para o grupo Financeiro
 */
namespace PremiacoesApi.Financeiro {
  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca os dados do comprovante de baixa manual realizada.
   *
   * @endpoint_api `/Financeiro/buscarDadosComprovanteBaixaManual`
   *
   * @enpoint `/premiations/finance/buscarDadosComprovanteBaixaManual`
   *
   * @method GET
   */
  export namespace BuscarDadosComprovanteBaixaManual {
    export interface DadosBaixa {
      idBaixa: number;
      dadosPagamento: DadosPagamento[];
    }

    export interface DadosPagamento {
      pagamento: Pagamento;
      premiacao: Premiacao;
      pedido: Pedido;
    }

    export interface Pagamento {
      idPagamento: number;
      descricao: string;
      valorPago: number;
      flagCancelamento: string;
      dataPagamento: string;
    }

    export interface Premiacao {
      idPremiacao: number;
      descricao: string;
      valorOriginal: number;
      valorPerdido: any;
      valorSaldo: number;
      valorPago: number;
      tipo: string;
      tipoPessoa: TipoPessoa;
    }

    export interface TipoPessoa {
      id: number;
      tipo: string;
      status: string;
      dataCriacao: string;
      dataManutencao: string;
    }

    export interface Pedido {
      idPedido: number;
      dataPedido: string;
      dataManutencao: string;
      idPedidoSiagri: string;
    }

    export type ApiResponse = DefaultFetchResponse<DadosBaixa>;

    export type ApiParams = { idBaixaManual: number };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/premiations/finance/buscarDadosComprovanteBaixaManual",
      method: API.HttpMethod.GET,
    };
  }
}

/**
 * @description Contém todas as definições de endpoints para o grupo Relatorios
 */
namespace PremiacoesApi.Relatorios {
  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que gera o relatório de premiações liberadas e não liberadas.
   *
   * @endpoint_api `/Relatorios/relatorio/premiacoes-liberadas-nao-liberadas`
   *
   * @enpoint `/premiations/report/generateReleasedUnreleasedAwardsReport`
   *
   * @method GET
   */
  export namespace GerarRelatorioPremiacoesLiberadasNaoLiberadas {

    export type ApiResponse = DefaultFetchResponse<any>;

    export type ApiParams = { listaClientes: number[], dataInicial: string, dataFinal: string };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/premiations/report/generateReleasedUnreleasedAwardsReport",
      method: API.HttpMethod.GET,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que gera o relatório de baixas manuais realizadas nas premiações.
   *
   * @endpoint_api `/Relatorios/relatorio/gerarRelatorioBaixaManual`
   *
   * @enpoint `/premiations/report/generateManualAwardsPayReport`
   *
   * @method GET
   */
  export namespace GerarRelatorioBaixaManualRealizada {

    export type ApiResponse = DefaultFetchResponse<any>;

    export type ApiParams = { idBaixaManual: number };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/premiations/report/generateManualAwardsPayReport",
      method: API.HttpMethod.GET,
    };
  }
}

export default PremiacoesApi;
