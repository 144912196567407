import React, { useEffect } from "react";
import { LinkButton, Select } from "../../../../components/Form";

import styles from "./List.module.css";
import { UseUserDevices } from "../hooks/useUserDevices";
import { Controller } from "react-hook-form";
import { Table } from "../../../../components/Data/XTable";
import { Circle } from "../../../../components/Loading";

interface Props {
  data: UseUserDevices;
}

export default function List({ data }: Props) {
  const { userDevicesTableData } = data;

  const {
    control,
    formState: { errors },
    watch,
  } = data.userForm;

  const user = watch("user");

  const { searchUsers, searchUserDevices, hasUserProfiles } = data.queries;

  useEffect(() => {
    data.setSelectedUserDevice(null);
    data.userDeviceForm.reset();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div>
        <Controller
          name={`user`}
          control={control}
          render={({ field }) => (
            <>
              <label htmlFor={field.name} className="label">
                Usuário
              </label>
              <Select
                {...field}
                value={user}
                onInputChange={(text) => {
                  searchUsers.fetchDebounced({ pesquisa: text });
                }}
                onChange={(value) => {
                  if (value != null) {
                    searchUserDevices.fetch({ idUsuario: value.value.idUsuario });
                  } else {
                    searchUserDevices.clearData();
                  }
                  field.onChange(value);
                }}
                options={searchUsers.data ?? []}
                placeholder="Selecione um usuário"
                error={errors.user?.message}
                isLoading={searchUsers.isFetching}
              />
            </>
          )}
        />
        {user !== null && (
          <LinkButton className={styles.addProfileButton} to="novo">
            Novo Dispositivo
          </LinkButton>
        )}
      </div>
      <span className="separator" />
      {!searchUserDevices.isFetching ? (
        <>
          {user !== null && hasUserProfiles ? (
            <div>
              <Table tableData={userDevicesTableData} />
            </div>
          ) : user === null ? (
            <p className="lineCardMessage">Selecione um usuário para buscar os dispositivos</p>
          ) : (
            <p className="lineCardMessage">Este usuário não possui dispositivos cadastrados</p>
          )}
        </>
      ) : (
        <div className="loadingContainer" style={{ height: "400px", justifyContent: "center" }}>
          <Circle size={100} />
        </div>
      )}
    </div>
  );
}
