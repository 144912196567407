import React from "react";
import { Route, Routes } from "react-router-dom";
import ReleasedUnreleasedPremiationsReport from "./ReleasedUnreleasedPremiationsReport/ReleasedUnreleasedPremiationsReport";

export function PremiationsReports() {
  return (
    <section>
      <Routes>
        <Route path="premiacoes-liberadas-nao-liberadas" element={<ReleasedUnreleasedPremiationsReport />} />
      </Routes>
    </section>
  );
}
