import { useForm } from "react-hook-form";
import useFetch from "../../../../../api/hooks/useFetch";
import { PremiacoesApi } from "../../../../../api/constants";
import { useCallback, useEffect } from "react";
import { downloadResponseFile } from "../../../../../helpers/responses";
import { useLoading } from "../../../../../hooks/async";
import useAxiosInstance from "../../../../../api/hooks/useAxiosInstance";
import { useModal } from "../../../../../hooks/contexts";

type Customer = {
  idCliente: number;
  nomeCliente: string;
};

type Form = {
  customers: ISelectOption<Customer>[];
  initialDate: string;
  finalDate: string;
};

export default function useReleasedUnreleasedPremiationsReport() {
  const axios = useAxiosInstance();
  const Modal = useModal();

  const form = useForm<Form>({
    mode: "onChange",
    defaultValues: {
      customers: [],
      initialDate: "",
      finalDate: "",
    },
  });

  const customers = form.watch("customers");

  const isFetchingReport = useLoading();

  const searchCustomers = useFetch(PremiacoesApi.Gerente.BuscarClientes.ApiInfo, {
    select(data) {
      return (
        data.object
          .filter((item) => !customers.some((element) => element.value.idCliente === item.cliente.idCliente))
          .map((item) => ({
            value: {
              idCliente: item.cliente.idCliente,
              nomeCliente: item.cliente.razaoSocial,
            },
            label: `${item.cliente.idCliente} | ${item.cliente.razaoSocial}`,
          })) ?? []
      );
    },
  });

  const generateReleasedUnreleasedAwardsReport = useCallback(
    async (params: PremiacoesApi.Relatorios.GerarRelatorioPremiacoesLiberadasNaoLiberadas.ApiParams) => {
      try {
        isFetchingReport.setLoading(true);

        const response = await axios(
          PremiacoesApi.Relatorios.GerarRelatorioPremiacoesLiberadasNaoLiberadas.ApiInfo.url,
          {
            method: PremiacoesApi.Relatorios.GerarRelatorioPremiacoesLiberadasNaoLiberadas.ApiInfo.method,
            params: params,
            responseType: "arraybuffer",
          }
        );

        if (response.status === 200) {
          downloadResponseFile({
            file: response.data,
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            fileName: "relatorio-premiacoes.xlsx",
          });
        }
      } catch (error: any) {
        Modal.error("Não foi possível gerar o relatório! Tente novamente mais tarde!");
      } finally {
        isFetchingReport.setLoading(false);
      }
    },
    [axios, isFetchingReport, Modal]
  );

  useEffect(() => {
    searchCustomers.fetch({ descricao: "" });

    return () => {
      searchCustomers.abort();
    };
  }, []); // eslint-disable-line

  return {
    form,
    searchCustomers,
    generateReleasedUnreleasedAwardsReport: {
      fetch: generateReleasedUnreleasedAwardsReport,
      isFetching: isFetchingReport.isLoading,
    },
  };
}

export type UseReleasedUnreleasedPremiationsReport = ReturnType<typeof useReleasedUnreleasedPremiationsReport>;
