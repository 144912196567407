import React from "react";

import { isValid } from "../../../../helpers/validations";

import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useChanges, useModal } from "../../../../hooks/contexts";
import { useForm, useSelect, useDate } from "../../../../hooks/form";

import { LinkButton, Button, Input, Select } from "../../../../components/Form";

import { Circle } from "../../../../components/Loading";

import styles from "./NewPriceTable.module.css";

export function NewPriceTable({ searchTotalPriceTableRecordsAndPriceTables }) {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const { setIsChangesDetected } = useChanges();
  const registeringPriceTable = useLoading();

  const priceTable = useForm({ type: "", required: true });
  const interestApplied = useForm({ type: "number", numberRange: { min: 0, max: 100 }, required: true });
  const chargeFreight = useSelect({ type: "single", required: true });
  const includeFreight = useSelect({ type: "single", required: true });
  const baseDate = useDate({ type: "", required: true });
  const interestType = useSelect({ type: "single", required: true });
  const freightValue = useForm({
    type: "number",
    numberRange: { min: 0.01 },
    required: !!includeFreight?.value?.value,
  });
  const personType = useSelect({ type: "single", required: true });
  const marginPhysicalPerson = useForm({ type: "number", required: false });
  const marginLegalPerson = useForm({ type: "number", required: false });
  const daysUntilInterestCollection = useForm({ type: "number", numberRange: { min: 0 }, required: true });
  const commissionType = useSelect({ type: "single", required: true, defaultValue: { value: "M", label: "Margem" } });
  const maximumDiscount = useForm({ type: "number", numberRange: { min: 0, max: 100 }, required: true });
  const dueDate = useDate({ type: "", required: true });
  const actionAfterDueDate = useSelect({ type: "single", required: true });
  const group = useSelect({ type: "single", required: true });
  const useMarginNegotiation = useSelect({ type: "single", required: true });
  const chargeICMS = useSelect({ type: "single", required: true });
  const chargeInterestOnFreight = useSelect({ type: "single", required: true });
  const freightType = useSelect({ type: "single", required: true });
  const minimumDueDate = useDate({ type: "", required: true });

  const chargeFreightOptions = React.useMemo(() => {
    return [
      {
        value: true,
        label: "Sim",
      },
      {
        value: false,
        label: "Não",
      },
    ];
  }, []);

  const includeFreightOptions = React.useMemo(() => {
    return [
      {
        value: true,
        label: "Sim",
      },
      {
        value: false,
        label: "Não",
      },
    ];
  }, []);

  const interestTypeOptions = React.useMemo(() => {
    return [
      {
        value: "C",
        label: "Dias Corridos",
      },
      {
        value: "M",
        label: "Data Média",
      },
    ];
  }, []);

  const personTypeOptions = React.useMemo(() => {
    return [
      {
        value: "F",
        label: "Pessoa Física",
      },
      {
        value: "J",
        label: "Pessoa Jurídica",
      },
    ];
  }, []);

  const actionAfterDueDateOptions = React.useMemo(() => {
    return [
      {
        value: "B",
        label: "Bloquear Incondicionalmente",
      },
      {
        value: "C",
        label: "Enviar Para o Comercial",
      },
    ];
  }, []);

  const commissionTypeOptions = React.useMemo(() => {
    return [
      {
        value: "M",
        label: "Margem",
      },
      {
        value: "D",
        label: "Desconto",
      },
    ];
  }, []);

  const useMarginNegotiationOptions = React.useMemo(() => {
    return [
      { value: "S", label: "Sim" },
      { value: "N", label: "Não" },
    ];
  }, []);

  const chargeICMSOptions = React.useMemo(() => {
    return [
      { value: "S", label: "Sim" },
      { value: "N", label: "Não" },
    ];
  }, []);

  const chargeInterestOnFreightOptions = React.useMemo(() => {
    return [
      { value: "S", label: "Sim" },
      { value: "N", label: "Não" },
    ];
  }, []);

  const freightTypeOptions = React.useMemo(() => {
    return [
      { value: "T", label: "Transportadora" },
      { value: "C", label: "Calculado" },
    ];
  }, []);

  const [groupOptions, setGroupOptions] = React.useState([]);

  const searchGroupOptions = React.useCallback(
    async (description = "") => {
      try {
        const json = await customFetch("/products/searchGroups", {
          body: {
            descricao: description,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((group) => {
            return {
              value: group.idGrupo,
              label: group.descricaoGrupo,
            };
          });
          setGroupOptions(options);
        }
      } catch (error) {
        Modal.error(error);
      }
    },
    [Modal, customFetch]
  );

  const clearForm = React.useCallback(() => {
    priceTable.reset();
    interestType.reset(interestTypeOptions[0]);
    chargeFreight.reset(chargeFreightOptions[0]);
    includeFreight.reset(includeFreightOptions[0]);
    personType.reset(personTypeOptions[0]);
    baseDate.reset();
    freightValue.reset();
    interestApplied.reset();
    marginPhysicalPerson.reset();
    marginLegalPerson.reset();
    daysUntilInterestCollection.reset();
    maximumDiscount.reset();
    dueDate.reset();
    actionAfterDueDate.reset(actionAfterDueDateOptions[0]);
    group.reset();
    useMarginNegotiation.reset(useMarginNegotiationOptions[0]);
    chargeICMS.reset(chargeICMSOptions[0]);
    chargeInterestOnFreight.reset(chargeInterestOnFreightOptions[0]);
    freightType.reset(freightTypeOptions[0]);
    minimumDueDate.reset();
    setIsChangesDetected(false);
  }, [
    baseDate,
    chargeFreight,
    chargeFreightOptions,
    freightValue,
    includeFreight,
    includeFreightOptions,
    interestApplied,
    daysUntilInterestCollection,
    interestType,
    interestTypeOptions,
    marginLegalPerson,
    marginPhysicalPerson,
    maximumDiscount,
    personType,
    personTypeOptions,
    priceTable,
    dueDate,
    actionAfterDueDate,
    actionAfterDueDateOptions,
    setIsChangesDetected,
    group,
    useMarginNegotiation,
    useMarginNegotiationOptions,
    chargeICMS,
    chargeICMSOptions,
    chargeInterestOnFreight,
    chargeInterestOnFreightOptions,
    freightType,
    freightTypeOptions,
    minimumDueDate,
  ]);

  const registerPriceTable = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        registeringPriceTable.setLoading(true);
        const json = await customFetch("/products/registerAndUpdatePriceTable", {
          body: {
            descricao: priceTable.value,
            dataBase: baseDate.toISOString(),
            jurosTabela: Number(interestApplied.value),
            situacao: true,
            freteObrigatorio: chargeFreight.value.value,
            tipoPessoa: personType.value.value,
            tipoComissao: commissionType.value.value,
            descontoTabela: Number(maximumDiscount.value),
            freteValor: Number(freightValue.value) || null,
            adicionarFrete: includeFreight.value.value,
            lucroJuridicaPadrao: Number(marginLegalPerson.value) || null,
            lucroFisicaPadrao: Number(marginPhysicalPerson.value) || null,
            tipoDeJuros: interestType.value.value,
            diasCobrarJuros: Number(daysUntilInterestCollection.value),
            vencimentoMaximo: dueDate.toISOString(),
            bloquearVencimento: actionAfterDueDate.value.value,
            idGrupo: group.value.value,
            usarNegociacaoMargem: useMarginNegotiation.value.value,
            cobrarICMS: chargeICMS.value.value,
            cobrarJurosFrete: chargeInterestOnFreight.value.value,
            tipoFrete: freightType.value.value,
            vencimentoMinimo: minimumDueDate.toISOString(),
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      } finally {
        registeringPriceTable.setLoading(false);
      }
    });
  }, [
    registeringPriceTable,
    customFetch,
    priceTable,
    baseDate,
    interestApplied,
    chargeFreight,
    personType,
    commissionType,
    maximumDiscount,
    freightValue,
    includeFreight,
    marginLegalPerson,
    marginPhysicalPerson,
    interestType,
    daysUntilInterestCollection,
    dueDate,
    actionAfterDueDate,
    group,
    useMarginNegotiation,
    chargeICMS,
    chargeInterestOnFreight,
    freightType,
    minimumDueDate,
  ]);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (
        isValid(
          priceTable,
          baseDate,
          baseDate,
          interestApplied,
          chargeFreight,
          personType,
          commissionType,
          maximumDiscount,
          freightValue,
          includeFreight,
          marginLegalPerson,
          marginPhysicalPerson,
          interestType,
          daysUntilInterestCollection,
          dueDate,
          actionAfterDueDate,
          group,
          useMarginNegotiation,
          chargeICMS,
          chargeInterestOnFreight,
          freightType,
          minimumDueDate
        )
      ) {
        try {
          const json = await registerPriceTable();
          if (json.status === 200) {
            await Modal.success(json.message);
            clearForm();
            searchTotalPriceTableRecordsAndPriceTables();
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        }
      }
    },
    [
      Modal,
      baseDate,
      chargeFreight,
      clearForm,
      daysUntilInterestCollection,
      freightValue,
      includeFreight,
      interestApplied,
      interestType,
      marginLegalPerson,
      marginPhysicalPerson,
      commissionType,
      maximumDiscount,
      personType,
      priceTable,
      registerPriceTable,
      searchTotalPriceTableRecordsAndPriceTables,
      dueDate,
      actionAfterDueDate,
      group,
      useMarginNegotiation,
      chargeICMS,
      chargeInterestOnFreight,
      freightType,
      minimumDueDate,
    ]
  );

  React.useEffect(() => {
    chargeFreight.setValue(chargeFreightOptions[0]);
    includeFreight.setValue(includeFreightOptions[0]);
    interestType.setValue(interestTypeOptions[0]);
    personType.setValue(personTypeOptions[0]);
    actionAfterDueDate.setValue(actionAfterDueDateOptions[0]);
    useMarginNegotiation.setValue(useMarginNegotiationOptions[0]);
    chargeICMS.setValue(chargeICMSOptions[0]);
    chargeInterestOnFreight.setValue(chargeInterestOnFreightOptions[0]);
    freightType.setValue(freightTypeOptions[0]);
    searchGroupOptions();
  }, []); // eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      {!registeringPriceTable.isLoading ? (
        <>
          <div>
            <LinkButton to="/produtos/tabela-de-precos" buttonStyle="backButton" />
          </div>
          <div>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className={styles.fieldsContainer}>
                <div className={styles.fieldDiv} data-grid="description">
                  <label htmlFor="priceTable" className="label">
                    Descrição
                  </label>
                  <Input
                    id="priceTable"
                    placeholder="Digite o nome da tabela de preços"
                    value={priceTable.value}
                    error={priceTable.error}
                    onChange={(e) => {
                      priceTable.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={priceTable.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="group">
                  <label htmlFor="group" className="label">
                    Grupo Formulado
                  </label>
                  <Select
                    id="group"
                    placeholder="Selecione o grupo do formulado"
                    options={groupOptions}
                    value={group.value}
                    error={group.error}
                    onChange={(e) => {
                      group.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={group.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="personType">
                  <label htmlFor="personType" className="label">
                    Tipo de Pessoa
                  </label>
                  <Select
                    id="personType"
                    options={personTypeOptions}
                    value={personType.value}
                    error={personType.error}
                    onChange={(value) => {
                      personType.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={personType.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="interestType">
                  <label htmlFor="interestType" className="label">
                    Tipo de Juros
                  </label>
                  <Select
                    id="interestType"
                    options={interestTypeOptions}
                    value={interestType.value}
                    error={interestType.error}
                    onChange={(value) => {
                      interestType.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={interestType.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="chargeFreight">
                  <label htmlFor="chargeFreight" className="label">
                    Cobrar Frete
                  </label>
                  <Select
                    id="chargeFreight"
                    options={chargeFreightOptions}
                    value={chargeFreight.value}
                    error={chargeFreight.error}
                    onChange={(value) => {
                      chargeFreight.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={chargeFreight.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="includeFreight">
                  <label htmlFor="includeFreight" className="label">
                    Incluir Frete no Produto
                  </label>
                  <Select
                    id="includeFreight"
                    options={includeFreightOptions}
                    value={includeFreight.value}
                    error={includeFreight.error}
                    onChange={(value) => {
                      includeFreight.onChange(value);
                      if (!value.value) freightValue.reset();
                      setIsChangesDetected(true);
                    }}
                    onBlur={includeFreight.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="marginPhysicalPerson">
                  <label htmlFor="marginPhysicalPerson" className="label">
                    Lucro Sobre Pessoa Física (%) (Opcional)
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="marginPhysicalPerson"
                    placeholder="Digite a margem de lucro sobre pessoa física"
                    value={marginPhysicalPerson.value}
                    error={marginPhysicalPerson.error}
                    onChange={(e) => {
                      marginPhysicalPerson.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={marginPhysicalPerson.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="marginLegalPerson">
                  <label htmlFor="marginLegalPerson" className="label">
                    Lucro Sobre Pessoa Jurídica (%) (Opcional)
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="marginLegalPerson"
                    placeholder="Digite a margem de lucro sobre pessoa jurídica"
                    value={marginLegalPerson.value}
                    error={marginLegalPerson.error}
                    onChange={(e) => {
                      marginLegalPerson.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={marginLegalPerson.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="interestApplied">
                  <label htmlFor="interestApplied" className="label">
                    Juros Aplicado (%)
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="interestApplied"
                    placeholder="Digite a porcentagem de juros aplicado"
                    value={interestApplied.value}
                    error={interestApplied.error}
                    onChange={(e) => {
                      interestApplied.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={interestApplied.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="daysUntilInterestCollection">
                  <label htmlFor="daysUntilInterestCollection" className="label">
                    Dias Até a Cobrança de Juros
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="daysUntilInterestCollection"
                    placeholder="Digite em quantos dias os juros passarão a ser contados"
                    value={daysUntilInterestCollection.value}
                    error={daysUntilInterestCollection.error}
                    onChange={(e) => {
                      daysUntilInterestCollection.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={daysUntilInterestCollection.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="minimumDueDate">
                  <label htmlFor="minimumDueDate" className="label">
                    Vencimento Mínimo
                  </label>
                  <Input
                    id="minimumDueDate"
                    type="date"
                    value={minimumDueDate.value}
                    error={minimumDueDate.error}
                    onChange={(e) => {
                      minimumDueDate.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={minimumDueDate.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="dueDate">
                  <label htmlFor="dueDate" className="label">
                    Vencimento Máximo
                  </label>
                  <Input
                    id="dueDate"
                    type="date"
                    value={dueDate.value}
                    error={dueDate.error}
                    onChange={(e) => {
                      dueDate.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={dueDate.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="actionAfterDueDate">
                  <label htmlFor="actionAfterDueDate" className="label">
                    Ação Após Vencimento Máximo
                  </label>
                  <Select
                    id="actionAfterDueDate"
                    options={actionAfterDueDateOptions}
                    value={actionAfterDueDate.value}
                    error={actionAfterDueDate.error}
                    onChange={(value) => {
                      actionAfterDueDate.onChange(value);
                      if (!value.value) freightValue.reset();
                      setIsChangesDetected(true);
                    }}
                    onBlur={actionAfterDueDate.onBlur}
                    isSearchable={false}
                    isClearable={false}
                    isDisabled={!dueDate.value.length}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="commissionType">
                  <label htmlFor="commissionType" className="label">
                    Tipo de Comissão
                  </label>
                  <Select
                    id="commissionType"
                    options={commissionTypeOptions}
                    value={commissionType.value}
                    onChange={commissionType.onChange}
                    isClearable={false}
                    isSearchable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="maximumDiscount">
                  <label htmlFor="maximumDiscount" className="label">
                    Aplicar Desconto De (%)
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="maximumDiscount"
                    placeholder="Digite a porcentagem máxima de desconto"
                    value={maximumDiscount.value}
                    error={maximumDiscount.error}
                    onChange={(e) => {
                      maximumDiscount.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={maximumDiscount.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="freightValue">
                  <label htmlFor="freightValue" className="label">
                    Preço do Frete (R$)
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="freightValue"
                    placeholder="Digite o preço do frete"
                    value={freightValue.value}
                    error={freightValue.error}
                    onChange={(e) => {
                      freightValue.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={freightValue.onBlur}
                    disabled={!includeFreight?.value?.value}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="baseDate">
                  <label htmlFor="baseDate" className="label">
                    Data de Vigor
                  </label>
                  <Input
                    id="baseDate"
                    type="date"
                    value={baseDate.value}
                    error={baseDate.error}
                    onChange={(e) => {
                      baseDate.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={baseDate.onBlur}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="useMarginNegotiation">
                  <label htmlFor="useMarginNegotiation" className="label">
                    Usar Negociação Margem
                  </label>
                  <Select
                    id="useMarginNegotiation"
                    options={useMarginNegotiationOptions}
                    value={useMarginNegotiation.value}
                    error={useMarginNegotiation.error}
                    onChange={(value) => {
                      useMarginNegotiation.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={useMarginNegotiation.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="chargeICMS">
                  <label htmlFor="chargeICMS" className="label">
                    Cobrar ICMS
                  </label>
                  <Select
                    id="chargeICMS"
                    options={chargeICMSOptions}
                    value={chargeICMS.value}
                    error={chargeICMS.error}
                    onChange={(value) => {
                      chargeICMS.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={chargeICMS.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="chargeInterestOnFreight">
                  <label htmlFor="chargeInterestOnFreight" className="label">
                    Cobrar Juros no Frete
                  </label>
                  <Select
                    id="chargeInterestOnFreight"
                    options={chargeInterestOnFreightOptions}
                    value={chargeInterestOnFreight.value}
                    error={chargeInterestOnFreight.error}
                    onChange={(value) => {
                      chargeInterestOnFreight.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={chargeInterestOnFreight.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div className={styles.fieldDiv} data-grid="freightType">
                  <label htmlFor="freightType" className="label">
                    Tipo Frete
                  </label>
                  <Select
                    id="freightType"
                    options={freightTypeOptions}
                    value={freightType.value}
                    error={freightType.error}
                    onChange={(value) => {
                      freightType.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={freightType.onBlur}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Cadastrar Tabela de Preços</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Cadastrando nova tabela de preços</span>
        </div>
      )}
    </div>
  );
}
