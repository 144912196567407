import React from "react";
import { Navigate } from "react-router-dom";

import styles from "./List.module.css";
import { Button, Input, LinkButton, Select } from "../../../../../components/Form";
import { formatMoney } from "../../../../../helpers/format";
import { Circle } from "../../../../../components/Loading";
import { ModalEditPercents } from "../components/ModalEditPercents/ModalEditPercents";

export default function List({ selectedTable, data }) {
  const {
    filterItemOptions,
    resetForm,
    item,
    searchItemOptionsDebounced,
    searchingItemOptions,
    costPrice,
    sellPrice,
    isFoliarTable,
    categoryOptions,
    category,
    searchingCategoryOptions,
    ggf,
    packaging,
    profitMargin,
    freight,
    icms,
    premiation,
    grossMargin,
    handleAddItem,
    isUpdatingItem,
    items,
    searchingItems,
    savingItems,
    itemFilter,
    lastModifiedId,
    setLastModifiedId,
    handleClickEditItem,
    modalEditPercentsRef,
    hasMicroPermission,
  } = data;

  if (!selectedTable.value) {
    return <Navigate to="/produtos/tabelas-de-vigencia" />;
  }

  return (
    <>
      <div className="container">
        <div className={styles.navigationContainer}>
          <LinkButton to="/produtos/tabelas-de-vigencia" buttonStyle="backButton" />
          <h2>{selectedTable.value.tabela.nome}</h2>
          {hasMicroPermission ? (
            <LinkButton className={styles.btnMicrosContainer} to="micros">
              Micros de Vigência
            </LinkButton>
          ) : (
            <span />
          )}
        </div>
        <div className={styles.fieldsContainer}>
          <div data-grid="item">
            <label htmlFor="item" className="label">
              Item
            </label>
            <Select
              id="item"
              placeholder="Selecione um item para adicionar"
              options={filterItemOptions()}
              value={item.value}
              error={item.error}
              onChange={(value) => {
                if (!value) {
                  resetForm();
                }
                item.onChange(value);
              }}
              onInputChange={searchItemOptionsDebounced}
              isLoading={searchingItemOptions.isLoading}
            />
          </div>
          <div data-grid="costPrice">
            <label htmlFor="costPrice" className="label">
              Preço de Custo (R$)
            </label>
            <Input
              id="costPrice"
              type="number"
              className="cleanInputNumber"
              placeholder="Digite o preço de custo do item"
              value={costPrice.value}
              error={costPrice.error}
              onChange={costPrice.onChange}
            />
          </div>
          <div data-grid="sellPrice">
            <label htmlFor="sellPrice" className="label">
              Preço de Venda (R$)
            </label>
            <Input
              id="sellPrice"
              type="number"
              className="cleanInputNumber"
              placeholder="Digite o preço de venda do item"
              value={sellPrice.value}
              error={sellPrice.error}
              onChange={sellPrice.onChange}
            />
          </div>
          {isFoliarTable && (
            <>
              <div data-grid="category">
                <label htmlFor="category" className="label">
                  Categoria
                </label>
                <Select
                  id="category"
                  placeholder="Selecione a categoria"
                  options={categoryOptions}
                  value={category.value}
                  error={category.error}
                  onChange={category.onChange}
                  isSearchable={false}
                  isLoading={searchingCategoryOptions.isLoading}
                />
              </div>
              <div data-grid="ggf">
                <label htmlFor="ggf" className="label">
                  GGF (%)
                </label>
                <Input
                  id="ggf"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite o GGF"
                  value={ggf.value}
                  error={ggf.error}
                  onChange={ggf.onChange}
                />
              </div>
              <div data-grid="packaging">
                <label htmlFor="packaging" className="label">
                  Embalagem (%)
                </label>
                <Input
                  id="packaging"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite a embalagem"
                  value={packaging.value}
                  error={packaging.error}
                  onChange={packaging.onChange}
                />
              </div>
              <div data-grid="profitMargin">
                <label htmlFor="profitMargin" className="label">
                  Margem Sugerida (%)
                </label>
                <Input
                  id="profitMargin"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite a margem sugerida"
                  value={profitMargin.value}
                  error={profitMargin.error}
                  onChange={profitMargin.onChange}
                />
              </div>
              <div data-grid="icms">
                <label htmlFor="icms" className="label">
                  ICMS (%)
                </label>
                <Input
                  id="icms"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite a porcentagem de ICMS"
                  value={icms.value}
                  error={icms.error}
                  onChange={icms.onChange}
                />
              </div>
              <div data-grid="freight">
                <label htmlFor="freight" className="label">
                  Frete (%)
                </label>
                <Input
                  id="freight"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite a porcentagem de frete"
                  value={freight.value}
                  error={freight.error}
                  onChange={freight.onChange}
                />
              </div>
              <div data-grid="premiation">
                <label htmlFor="premiation" className="label">
                  Premiação
                </label>
                <Input
                  id="premiation"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite a premiação"
                  value={premiation.value}
                  error={premiation.error}
                  onChange={premiation.onChange}
                />
              </div>
              <div data-grid="grossMargin">
                <label htmlFor="grossMargin" className="label">
                  Margem Bruta (%)
                </label>
                <Input
                  id="grossMargin"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite a margem bruta"
                  value={grossMargin.value}
                  error={grossMargin.error}
                  onChange={grossMargin.onChange}
                />
              </div>
            </>
          )}
          <div data-grid="buttonSubmit">
            <Button className={styles.addItemButton} onClick={handleAddItem}>
              {isUpdatingItem() ? "Atualizar Item" : "Adicionar Item"}
            </Button>
          </div>
        </div>
        <span className="separator" />
        <div className={styles.dataContainer}>
          {items.length && !searchingItems.isLoading && !savingItems.isLoading ? (
            <>
              <div>
                <label htmlFor="itemFilter" className="label">
                  Filtrar Item
                </label>
                <Input
                  id="itemFilter"
                  placeholder="Digite um item para filtrar"
                  value={itemFilter.value}
                  onChange={itemFilter.onChange}
                />
              </div>
              <div className={styles.itensContainer}>
                <ul>
                  {items
                    .filter((item) => {
                      if (itemFilter.value) {
                        return item.formulado.descricaoFormulado.includes(itemFilter.value);
                      }
                      return true;
                    })
                    .map((item, index) => (
                      <li
                        className={`${styles.item} ${
                          item.formulado.idFormulado === lastModifiedId ? styles.item_selected : ""
                        }`}
                        key={index}
                      >
                        <div>
                          <div>
                            <span className={styles.item__name}>{item.formulado.descricaoFormulado}</span>
                          </div>
                          <ul className={styles.item__prices}>
                            <li>Preço de Venda: {formatMoney(item.itensTabela.precoOriginal)}</li>
                          </ul>
                          <span className={styles.item_buttonSeparator}></span>
                          <div className={styles.editButtonsContainer}>
                            <Button
                              variant="neutral"
                              className={styles.item_button}
                              onClick={() => {
                                setLastModifiedId(item.formulado.idFormulado);
                                handleClickEditItem(item);
                              }}
                            >
                              Editar Item
                            </Button>
                            {isFoliarTable && (
                              <Button
                                variant="neutral"
                                className={styles.item_button}
                                onClick={() => {
                                  setLastModifiedId(item.formulado.idFormulado);
                                  modalEditPercentsRef.current.show(item.itensTabela.id);
                                }}
                              >
                                Editar Percentuais
                              </Button>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </>
          ) : searchingItems.isLoading ? (
            <div className={`loadingContainer`}>
              <Circle size={100} />
              <span className="loadingMessage">Buscando Itens da Tabela</span>
            </div>
          ) : savingItems.isLoading ? (
            <div className={`loadingContainer`}>
              <Circle size={100} />
              <span className="loadingMessage">Salvando Itens na Tabela</span>
            </div>
          ) : (
            <p className={`lineCardMessage`}>Nenhum item encontrado na tabela</p>
          )}
        </div>
      </div>
      <ModalEditPercents ref={modalEditPercentsRef} />
    </>
  );
}
