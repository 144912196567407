import React, { ChangeEvent } from "react";

import styles from "./NewRange.module.css";

import { Button, LinkButton, Select } from "../../../../../components/Form";
import { CaretLeft, CaretRight, Plus } from "phosphor-react";
import { useModal } from "../../../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useSelect } from "../../../../../hooks/form";
import { isValid } from "../../../../../helpers/validations";
import { Circle } from "../../../../../components/Loading";
import { useNavigate } from "react-router-dom";

interface IRangeItems {
  idFaixaPrecoproduto: number | string | null;
  idFormulado: number | string | null;
  idEmpresa: number | string | null;
  idTabela: number | string;
  perMinimo: number | string;
  perMaximo: number | string;
  status: "A" | "I";
  comissaoValor: number | string;
  rgb: string;
  dataCriacao: string | null;
  dataManutencao: string | null;
  novaFaixa?: "S" | "N";
  observacao: string;
  ocultar: "S" | "N";
}

interface ITable {
  idTabela: number;
  descricaoTabela: string;
  tipoComissao: "M" | "D";
}

interface ISearchTableResponse {
  response: number;
  message: string;
  status: number;
  object: any;
}

interface IFormula {
  idItemTabela: number;
  idItem: number;
  formulado: {
    idFormulado: number;
    descricaoFormulado: string;
  };
  idEmpresa: number;
  empresa: {
    idEmpresa: number;
    nomeEmpresa: string;
    nomeFantasia: string;
  };
  precoCusto: number;
  descontoMaximo: number;
  dataManutencao: string;
  usuarioAlteracao: string;
  usuarioInsert: string;
  margemLucro: number;
  valorProduto: number;
  idTabela: number;
  tabela: {
    idTabela: number;
    descricaoTabela: string;
  };
  tabPrecoModificavel: string;
  situacaoProduto: string;
}

interface ISearchFormulaResponse {
  response: number;
  message: string;
  status: number;
  object: any;
}

interface ISendNewRangeResponse {
  response: number;
  message: string;
  status: number;
  object: any;
}

interface IProps {
  searchTotalCommissionTableRecordsAndCommissionTables: (description?: string) => void;
}

export function NewRange({ searchTotalCommissionTableRecordsAndCommissionTables }: IProps) {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const [rangeItems, setRangeItems] = React.useState<IRangeItems[]>([]);

  const [selectedRange, setSelectedRange] = React.useState<IRangeItems | null>({
    idFaixaPrecoproduto: -1,
    idFormulado: null,
    idEmpresa: null,
    idTabela: "",
    perMinimo: "0",
    perMaximo: "0",
    status: "A",
    comissaoValor: "0",
    rgb: "#E6E6E6",
    dataCriacao: null,
    dataManutencao: null,
    novaFaixa: "S",
    observacao: "",
    ocultar: "N",
  });

  const searchingTableOptions = useLoading();
  const [tableOptions, setTableOptions] = React.useState<ITable[]>([]);
  const table = useSelect({ type: "single", required: true });

  const searchingFormulaOptions = useLoading();
  const [formulaOptions, setFormulaOptions] = React.useState<IFormula[]>([]);
  const formula = useSelect({ type: "single", required: true });

  // const [disableLastRanges, setDisableLastRanges] = React.useState(false);

  const sendingNewRange = useLoading();

  const searchTables = React.useCallback(async () => {
    try {
      searchingTableOptions.setLoading(true);
      const json = (await customFetch("/commissions/searchFormulaTables", {
        headers: undefined,
        body: undefined,
      })) as ISearchTableResponse;
      if (json.status === 200) {
        const options = json.object.map((item: any) => ({
          value: item,
          label: `${item.idTabela} | ${item.descricaoTabela}`,
        }));
        setTableOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setTableOptions([]);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingTableOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingTableOptions]);

  const searchFormulas = React.useCallback(
    async (tableId: number) => {
      try {
        searchingFormulaOptions.setLoading(true);
        const json = (await customFetch("/commissions/searchProductFormulas", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: { idTabela: tableId },
        })) as ISearchFormulaResponse;
        if (json.status === 200) {
          const options = json.object.map((item: any) => ({
            value: item,
            label: `${item.formulado.idFormulado} | ${item.formulado.descricaoFormulado}`,
          }));
          setFormulaOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setFormulaOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingFormulaOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingFormulaOptions]
  );

  const onSelectRange = React.useCallback(
    (range: IRangeItems | null = null) => {
      if (range !== null) {
        setSelectedRange(range);
      } else {
        const lastRange = rangeItems[rangeItems.length - 1];

        let perMinimoNovaFaixa = "0";
        let perMaximoNovaFaixa = "0";

        if (table.value?.value.tipoComissao === "D" && lastRange) {
          perMinimoNovaFaixa = String(Number(lastRange.perMaximo) + 0.000001);
        } else if (table.value?.value.tipoComissao === "M" && lastRange) {
          perMaximoNovaFaixa = String(Number(lastRange.perMinimo) - 0.000001);
        }

        setSelectedRange({
          idFaixaPrecoproduto: 0,
          idFormulado: null,
          idEmpresa: null,
          idTabela: "",
          perMinimo: perMinimoNovaFaixa,
          perMaximo: perMaximoNovaFaixa,
          status: "A",
          comissaoValor: "0",
          rgb: "#E6E6E6",
          dataCriacao: null,
          dataManutencao: null,
          novaFaixa: "S",
          observacao: "",
          ocultar: "N",
        });
      }
    },
    [rangeItems, table.value?.value.tipoComissao]
  );

  const onSelectRangeByArrow = React.useCallback(
    (direction: "right" | "left") => {
      const qtdItems = rangeItems.length;
      if (qtdItems > 0) {
        const currentIndex = rangeItems.findIndex(
          (item) => item.idFaixaPrecoproduto === selectedRange?.idFaixaPrecoproduto
        );
        if (direction === "right") {
          if (currentIndex === qtdItems - 1) {
            setSelectedRange(rangeItems[0]);
          } else {
            setSelectedRange(rangeItems[currentIndex + 1]);
          }
        } else {
          if (currentIndex === 0) {
            setSelectedRange(rangeItems[qtdItems - 1]);
          } else if (currentIndex === -1) {
            setSelectedRange(rangeItems[qtdItems - 1]);
          } else {
            setSelectedRange(rangeItems[currentIndex - 1]);
          }
        }
      }
    },
    [rangeItems, selectedRange]
  );

  const onChangeSelectedRangeValues = React.useCallback(
    (
      type: "letter" | "minDiscount" | "maxDiscount" | "commission" | "color" | "hideRange",
      event: ChangeEvent<HTMLInputElement>
    ) => {
      let object = selectedRange;
      if (type === "letter") {
        object = { ...(selectedRange as IRangeItems), observacao: event.target.value.toUpperCase() };
      } else if (type === "minDiscount") {
        object = { ...(selectedRange as IRangeItems), perMinimo: event.target.value };
      } else if (type === "maxDiscount") {
        object = { ...(selectedRange as IRangeItems), perMaximo: event.target.value };
      } else if (type === "commission") {
        object = { ...(selectedRange as IRangeItems), comissaoValor: event.target.value };
      } else if (type === "color") {
        object = { ...(selectedRange as IRangeItems), rgb: event.target.value };
      } else {
        object = { ...(selectedRange as IRangeItems), ocultar: event.target.checked ? "S" : "N" };
      }
      setSelectedRange(object);
    },
    [selectedRange]
  );

  const onRemoveRange = React.useCallback(() => {
    const array = rangeItems.filter((item) => item.idFaixaPrecoproduto !== selectedRange?.idFaixaPrecoproduto);
    const qtdItems = rangeItems.length;
    const currentIndex = rangeItems.findIndex(
      (item) => item.idFaixaPrecoproduto === selectedRange?.idFaixaPrecoproduto
    );

    setRangeItems(array);

    if (qtdItems === 1) {
      setSelectedRange({
        idFaixaPrecoproduto: 0,
        idFormulado: null,
        idEmpresa: null,
        idTabela: "",
        perMinimo: "0",
        perMaximo: "0",
        status: "A",
        comissaoValor: "0",
        rgb: "#E6E6E6",
        dataCriacao: null,
        dataManutencao: null,
        novaFaixa: "S",
        observacao: "",
        ocultar: "N",
      });
    } else {
      if (currentIndex === qtdItems - 1) {
        setSelectedRange(array[currentIndex - 1]);
      } else {
        setSelectedRange(array[currentIndex]);
      }
    }
  }, [rangeItems, selectedRange]);

  const onConcludeRangeChanges = React.useCallback(() => {
    if (!selectedRange?.perMinimo || !selectedRange?.perMaximo || !selectedRange?.comissaoValor || !selectedRange.rgb) {
      return;
    }
    const array = rangeItems?.filter(
      (item) => String(item.idFaixaPrecoproduto) !== String(selectedRange?.idFaixaPrecoproduto)
    );
    const hasSameItem = -1;

    const lastNegativeId = rangeItems?.reduce((prev, cur) => {
      const val = prev > Number(cur.idFaixaPrecoproduto) ? Number(cur.idFaixaPrecoproduto) : prev;
      return val;
    }, 0);

    let idFaixaPrecoproduto = -1;

    if (lastNegativeId !== undefined) {
      idFaixaPrecoproduto = lastNegativeId - 1;
    }

    if (array !== undefined && hasSameItem !== undefined) {
      if (hasSameItem < 0) {
        const newRange = {
          ...selectedRange,
          novaFaixa: "N",
          idFaixaPrecoproduto: idFaixaPrecoproduto,
        };

        setRangeItems(
          [...array, newRange].sort((a, b) => {
            if (table.value?.value.tipoComissao === "M") return Number(b.perMaximo) - Number(a.perMaximo);
            else return Number(a.perMaximo) - Number(b.perMaximo);
          }) as IRangeItems[]
        );

        let perMinimoNovaFaixa = "0";
        let perMaximoNovaFaixa = "0";

        if (table.value?.value.tipoComissao === "D" && newRange) {
          perMinimoNovaFaixa = String(Number(newRange.perMaximo) + 0.000001);
        } else if (table.value?.value.tipoComissao === "M" && newRange) {
          perMaximoNovaFaixa = String(Number(newRange.perMinimo) - 0.000001);
        }

        setSelectedRange({
          idFaixaPrecoproduto: 0,
          idFormulado: null,
          idEmpresa: null,
          idTabela: "",
          perMinimo: perMinimoNovaFaixa,
          perMaximo: perMaximoNovaFaixa,
          status: "A",
          comissaoValor: "0",
          rgb: "#E6E6E6",
          dataCriacao: null,
          dataManutencao: null,
          novaFaixa: "S",
          observacao: "",
          ocultar: "N",
        } as IRangeItems);
      } else {
        Modal.error(`Já há um item com desconto máximo de ${selectedRange?.perMaximo}!`);
      }
    }
  }, [rangeItems, selectedRange, Modal, table]);

  const onSubmitNewRange = React.useCallback(async () => {
    if (!isValid(table, formula)) {
      return;
    } else if (!rangeItems.length) {
      Modal.error("Não é possível criar uma faixa vazia!");
      return;
    }
    try {
      sendingNewRange.setLoading(true);
      const requestBody = rangeItems.map((item, index, array) => ({
        idFormulado: Number((formula.value as unknown as any).value.formulado.idFormulado),
        idEmpresa: Number((formula.value as unknown as any).value.idEmpresa),
        idTabela: Number((table.value as unknown as any).value.idTabela),
        // perMinimo: array[index - 1] ? Number(array[index - 1].perMaximo) + 0.00001 : 0,
        perMinimo: Number(item.perMinimo),
        perMaximo: Number(item.perMaximo),
        comissaoValor: Number(item.comissaoValor),
        rgb: item.rgb,
        status: "A",
        observacao: item.observacao,
        ocultar: item.ocultar,
        novaTabela: true,
      }));
      const json = (await customFetch("/commissions/insertAndUpdateRange/true", {
        headers: undefined,
        body: requestBody,
      })) as ISendNewRangeResponse;
      if (json.status === 200) {
        searchTotalCommissionTableRecordsAndCommissionTables();
        await Modal.success(json.message);
        navigate("/comissoes/adubo/tabelas-de-comissoes");
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      sendingNewRange.setLoading(false);
    }
  }, [
    Modal,
    customFetch,
    formula,
    rangeItems,
    searchTotalCommissionTableRecordsAndCommissionTables,
    sendingNewRange,
    table,
    navigate,
  ]);

  React.useEffect(() => {
    searchTables();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!sendingNewRange.isLoading ? (
        <>
          <div className={styles.navigationContainer}>
            <LinkButton to="/comissoes/adubo/tabelas-de-comissoes" buttonStyle="backButton" />
          </div>
          <span className={styles.separator}></span>
          <div>
            <div className={styles.fieldsContainer}>
              <div className={styles.fieldsContainer__table}>
                <label htmlFor="table" className="label">
                  Tabela
                </label>
                <Select
                  id="table"
                  placeholder="Selecione uma tabela"
                  options={tableOptions}
                  value={table.value}
                  error={table.error}
                  onChange={(value: any) => {
                    formula.reset();
                    setFormulaOptions([]);
                    table.onChange(value);
                    if (value) {
                      searchFormulas(Number(value.value.idTabela));
                      const items = JSON.parse(JSON.stringify(rangeItems));
                      items.sort((a: any, b: any) => {
                        if (value.tipoComissao === "M") return Number(b.perMaximo) - Number(a.perMaximo);
                        else return Number(a.perMaximo) - Number(b.perMaximo);
                      });
                      setRangeItems(JSON.parse(JSON.stringify(items)));
                    }
                  }}
                  onBlur={table.onBlur}
                  defaultValue={undefined}
                  isLoading={searchingTableOptions.isLoading}
                />
              </div>
              <div>
                <label htmlFor="formula" className="label">
                  Formulado
                </label>
                <Select
                  id="formula"
                  placeholder="Selecione uma formula"
                  options={formulaOptions}
                  value={formula.value}
                  error={formula.error}
                  onChange={formula.onChange}
                  onBlur={formula.onBlur}
                  defaultValue={undefined}
                  isLoading={searchingFormulaOptions.isLoading}
                  isDisabled={!table.value}
                />
              </div>
            </div>
            <span className={styles.separator}></span>
            <div className={styles.rangeContainer}>
              {rangeItems !== undefined &&
                rangeItems.map((commission, commissionIndex) => (
                  <div
                    className={`${styles.commissionBlock} ${
                      selectedRange?.idFaixaPrecoproduto === commission.idFaixaPrecoproduto ? styles.isActive : ""
                    }`}
                    key={commission.idFaixaPrecoproduto}
                    onClick={() => {
                      onSelectRange(commission);
                    }}>
                    <p>
                      {table.value?.value.tipoComissao === "M" ? "Mar" : "Des"}. {commission.perMaximo}%
                    </p>
                    <div className={styles.ball}>
                      <div style={{ backgroundColor: commission.rgb }}></div>
                      <span style={{ backgroundColor: commission.rgb }}></span>
                      <p>{commission.observacao}</p>
                    </div>
                    <p>Com. {commission.comissaoValor}%</p>
                  </div>
                ))}
              <div
                className={styles.newBallContainer}
                onClick={() => {
                  onSelectRange();
                }}>
                <div className={styles.newBall}>
                  <div>
                    <Plus weight="bold" size={"2rem"} color="var(--gray-5)" />
                  </div>
                  <span></span>
                </div>
              </div>
            </div>
            <div className={styles.editableContainer}>
              <div className={styles.editableContainer__mainContainer}>
                <button
                  className={styles.arrowButton}
                  onClick={() => {
                    onSelectRangeByArrow("left");
                  }}>
                  <CaretLeft size={"3rem"} weight="fill" />
                </button>
                <div className={styles.editableContainer__range}>
                  <div className={styles.rangeFieldsContainer}>
                    <div className={styles.field}>
                      <label className="label">Letra</label>
                      <input
                        type="text"
                        className={`${styles.customInputNumber}`}
                        maxLength={1}
                        value={selectedRange?.observacao || ""}
                        onChange={(event) => {
                          if (event.target.value.length > 1 || !/^[a-zA-Z]*$/.test(event.target.value)) return;
                          onChangeSelectedRangeValues("letter", event);
                        }}
                      />
                    </div>
                    <div className={styles.rangeFieldsContainer__ballContainer}>
                      <div className={styles.field}>
                        <label htmlFor="minDiscount" className="label">
                          Percentual Mínimo (%)
                        </label>
                        <input
                          type="number"
                          className={`cleanInputNumber ${styles.customInputNumber}`}
                          value={selectedRange?.perMinimo}
                          onChange={(event) => {
                            onChangeSelectedRangeValues("minDiscount", event);
                          }}
                          onWheel={(event) => {
                            event.preventDefault();
                            event.currentTarget.blur();
                          }}
                        />
                      </div>
                      <div>
                        <input
                          type="color"
                          className={`${styles.customInputColor}`}
                          value={selectedRange?.rgb}
                          onChange={(event) => {
                            onChangeSelectedRangeValues("color", event);
                          }}
                        />
                      </div>
                      <div className={styles.field}>
                        <label htmlFor="maxDiscount" className="label">
                          Percentual Máximo (%)
                        </label>
                        <input
                          type="number"
                          className={`cleanInputNumber ${styles.customInputNumber}`}
                          value={selectedRange?.perMaximo}
                          onChange={(event) => {
                            onChangeSelectedRangeValues("maxDiscount", event);
                          }}
                          onWheel={(event) => {
                            event.preventDefault();
                            event.currentTarget.blur();
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.field}>
                      <label htmlFor="commission" className="label">
                        Valor Comissão (%)
                      </label>
                      <input
                        type="number"
                        className={`cleanInputNumber ${styles.customInputNumber}`}
                        value={selectedRange?.comissaoValor}
                        onChange={(event) => {
                          onChangeSelectedRangeValues("commission", event);
                        }}
                        onWheel={(event) => {
                          event.preventDefault();
                          event.currentTarget.blur();
                        }}
                      />
                    </div>
                    <div className={styles.field}>
                      <label className={`label ${styles.rangeFieldsContainer__hideRangeInput}`}>
                        <input
                          type="checkbox"
                          checked={selectedRange?.ocultar === "S"}
                          onChange={(event) => {
                            onChangeSelectedRangeValues("hideRange", event);
                          }}
                        />
                        Ocultar Faixa
                      </label>
                    </div>
                  </div>
                  <div className={styles.buttonsRangeContainer}>
                    {selectedRange?.novaFaixa !== "S" ? (
                      <Button
                        variant="danger"
                        onClick={() => {
                          onRemoveRange();
                        }}>
                        Remover
                      </Button>
                    ) : null}
                    <Button
                      onClick={() => {
                        onConcludeRangeChanges();
                      }}
                      disabled={!selectedRange?.perMaximo || !selectedRange?.comissaoValor || !selectedRange?.rgb}>
                      Salvar
                    </Button>
                  </div>
                </div>
                <button
                  className={styles.arrowButton}
                  onClick={() => {
                    onSelectRangeByArrow("right");
                  }}>
                  <CaretRight size={"3rem"} weight="fill" />
                </button>
              </div>
            </div>
            <div className={styles.finishButtonContainer}>
              <div className={styles.disableLastRangesContainer}>
                <label className="label">
                  <input
                    type="checkbox"
                    onChange={({ target }) => {
                      // setDisableLastRanges(target.checked);
                    }}
                    checked={true}
                  />{" "}
                  Desativar faixas anteriores desta formula
                </label>
              </div>
              <Button
                className={styles.concludeButton}
                onClick={() => {
                  onSubmitNewRange();
                }}
                disabled={!rangeItems.length}>
                Concluir Nova Faixa
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Salvando Nova Faixa</span>
        </div>
      )}
    </div>
  );
}
