import React, { useRef } from "react";

import { formatDate } from "../../../../helpers/format";

import { LinkButton, Button, Input, Select } from "../../../../components/Form";
import { Table, THead, TBody, Tr, Th, Td } from "../../../../components/Data/Table";
import { Paginate } from "../../../../components/Paginate/Paginate";

import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../../hooks/contexts";

import { Circle } from "../../../../components/Loading";
import { Pencil } from "phosphor-react";

import styles from "./PriceTableList.module.css";
import { Controller, useForm } from "react-hook-form";
import excelExport from "../../../../utils/ExcelExport";

export function PriceTableList({
  pagination,
  searchedPriceTable,
  searchPriceTables,
  searchTotalPriceTableRecordsAndPriceTables,
  priceTableList,
  searchingPriceTables,
  setSelectedPriceTable,
  enterpriseOptions,
  priceTableOptions,
  searchingEnterpriseOptions,
  searchingPriceTableOptions,
}) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const modalReport = useRef(null);

  const formulasReportForm = useForm({
    defaultValues: {
      priceTable: null,
      enterprise: null,
    },
  });

  const priceTable = formulasReportForm.watch("priceTable");
  const enterprise = formulasReportForm.watch("enterprise");

  const generatingReport = useLoading();

  const generateFormulasReport = async ({
    priceTableId,
    priceTableDescription,
    enterpriseId,
    enterpriseDescription,
  }) => {
    try {
      generatingReport.setLoading(true);
      const json = await customFetch("/products/searchItemsOfPriceTable", {
        body: {
          idTabela: priceTableId,
          idEmpresa: enterpriseId,
          descricaoItem: "",
        },
      });
      if (json.status === 200) {
        const data = json.object.map((item) => ({
          "ID Fórmula": item.formulado.idFormulado,
          Nomenclatura: item.formulado.descricaoFormulado,
          Situação: item.itens.situacaoProduto === "A" ? "Ativo" : "Inativo",
          "ID Grupo": item.grupo.idGrupo,
          "Nome Grupo": item.grupo.descricaoGrupo,
        }));

        excelExport({ data, fileName: `relatório-fórmulas-${priceTableDescription}-${enterpriseDescription}` });
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        Modal.alert(`Não há itens na ${priceTableDescription} da empresa ${enterpriseDescription}`);
      }
    } catch (error) {
      Modal.error(error.message);
    } finally {
      generatingReport.setLoading(false);
    }
  };

  const handlePageChange = React.useCallback(
    async (priceTable, page) => {
      try {
        searchingPriceTables.setLoading(true);
        const json = await searchPriceTables(priceTable, page);
        if (json.status === 200) {
          priceTableList.setValue(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingPriceTables.setLoading(false);
      }
    },
    [Modal, priceTableList, searchPriceTables, searchingPriceTables]
  );

  const handleClickEditButton = React.useCallback(
    (priceTableData) => {
      setSelectedPriceTable(priceTableData);
      navigate("atualizar");
    },
    [navigate, setSelectedPriceTable]
  );

  const { current: searchPriceTableList } = React.useRef(
    useDebounce((priceTable) => {
      searchTotalPriceTableRecordsAndPriceTables(priceTable);
    })
  );

  return (
    <>
      <>
        <div className={`container ${styles.container}`}>
          {!generatingReport.isLoading ? (
            <>
              <div className={`${styles.buttonContainer}`}>
                <Button
                  onClick={() => {
                    modalReport.current.show();
                  }}
                >
                  Gerar Relatório de Fórmulas
                </Button>
                <LinkButton to="novo">Nova Tabela de Preços</LinkButton>
              </div>
              <div className={`${styles.searchContainer}`}>
                <label className="label">Filtrar Tabela de Preços</label>
                <Input
                  placeholder="Busque por uma tabela de preços"
                  value={searchedPriceTable.value}
                  onChange={({ target }) => {
                    searchedPriceTable.setValue(target.value);
                    searchPriceTableList(target.value);
                  }}
                />
              </div>
              <div className={`${styles.priceTableListContainer}`}>
                {priceTableList.value.length && !searchingPriceTables.isLoading ? (
                  <Table title="Tabelas de Preços Cadastradas" className={styles.priceTable}>
                    <THead>
                      <Tr>
                        <Th>ID</Th>
                        <Th>Descrição</Th>
                        <Th>Juros (%)</Th>
                        <Th>Situação</Th>
                        <Th>Última modificação</Th>
                        <Th width="6.25rem"></Th>
                      </Tr>
                    </THead>
                    <TBody>
                      {priceTableList.value.map((priceTable) => {
                        return (
                          <Tr key={priceTable.tabela.idTabela}>
                            <Td heading="ID">{priceTable.tabela.idTabela}</Td>
                            <Td heading="Descrição">{priceTable.tabela.descricaoTabela}</Td>
                            <Td heading="Juros (%)">{`${priceTable.tabela.jurosTabela}`}</Td>
                            <Td heading="Situação">{priceTable.tabela.situacaoTabela === "S" ? "Ativo" : "Inativo"}</Td>
                            <Td heading="Última modificação">
                              {formatDate(priceTable.tabela.dataManutencao, "dd/MM/yyyy às hh:mm:ss")}
                            </Td>
                            <Td heading="Editar" data-option>
                              <Button
                                className={styles.editPriceTableButton}
                                onClick={() => {
                                  const selectedGroup = {
                                    ...priceTable,
                                    status: !!(priceTable.tabela.situacaoTabela === "S"),
                                  };
                                  handleClickEditButton(selectedGroup);
                                }}
                                data-option-button
                              >
                                <Pencil weight="fill" />
                              </Button>
                            </Td>
                          </Tr>
                        );
                      })}
                    </TBody>
                  </Table>
                ) : searchingPriceTables.isLoading ? (
                  <div className={styles.searchingPriceTablesLoadingContainer}>
                    <Circle size={100} />
                  </div>
                ) : (
                  <p className={styles.noPriceTableMessage}>Nenhuma tabela de preços encontrada</p>
                )}
              </div>
              <div>
                <Paginate
                  classNameContainer={styles.paginationContainer}
                  totalRecords={pagination.totalRecords}
                  maxItems={pagination.maxItems}
                  currentPage={pagination.currentPage}
                  setCurrentPage={pagination.setCurrentPage}
                  onPageChange={(page) => handlePageChange(searchedPriceTable.value, page)}
                />
              </div>
            </>
          ) : (
            <div className={styles.searchingPriceTablesLoadingContainer} style={{ height: "500px" }}>
              <Circle size={100} />
            </div>
          )}
        </div>
      </>
      <dialog
        className={`modal ${styles.modalReason}`}
        ref={modalReport}
        onClose={() => {
          formulasReportForm.reset();
        }}
      >
        <div className="modalContent">
          <div>
            <Controller
              name="priceTable"
              control={formulasReportForm.control}
              rules={{
                required: "Selecione a tabela de preços",
              }}
              render={({ field }) => (
                <div>
                  <label htmlFor="priceTable" className="label">
                    Tabela de Preços
                  </label>
                  <Select
                    {...field}
                    value={priceTable}
                    id="priceTable"
                    options={priceTableOptions}
                    placeholder="Selecione a tabela de preços"
                    error={formulasReportForm.formState.errors.priceTable?.message}
                    isLoading={searchingPriceTableOptions}
                  />
                </div>
              )}
            />
            <Controller
              name="enterprise"
              control={formulasReportForm.control}
              rules={{
                required: "Selecione a empresa",
              }}
              render={({ field }) => (
                <div style={{ marginTop: "10px" }}>
                  <label htmlFor="enterprise" className="label">
                    Empresa
                  </label>
                  <Select
                    {...field}
                    value={enterprise}
                    id="enterprise"
                    options={enterpriseOptions}
                    placeholder="Selecione a empresa"
                    error={formulasReportForm.formState.errors.enterprise?.message}
                    isLoading={searchingEnterpriseOptions}
                  />
                </div>
              )}
            />
          </div>
          <span className="separator"></span>
          <div className={styles.modalReportButtonContainer}>
            <Button
              className={styles.modalExcelType__buttonCancel}
              variant="danger"
              onClick={() => {
                modalReport.current.close();
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                formulasReportForm.handleSubmit((data) => {
                  generateFormulasReport({
                    priceTableId: data.priceTable.value.idTabela,
                    priceTableDescription: data.priceTable.value.descricaoTabela,
                    enterpriseId: data.enterprise.value.idEmpresa,
                    enterpriseDescription: data.enterprise.value.nomeEmpresa,
                  });

                  modalReport.current.close();
                })();
              }}
            >
              Gerar
            </Button>
          </div>
        </div>
      </dialog>
    </>
  );
}
