import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, LinkButton } from "../../../../components/Form";
import { Circle } from "../../../../components/Loading";
import { formatDate } from "../../../../helpers/format";
import { useDebounce, useInfiniteScroll } from "../../../../hooks/async";

import styles from "./ValidityTableList.module.css";

export function ValidityTableList({
  setSelectedTable,
  searchedValidity,
  noMoreValidity,
  currentPage,
  searchValidityList,
  searchingValidityList,
  validityList,
}) {
  const navigate = useNavigate();

  const [loadMoreRef] = useInfiniteScroll(() => {
    if (searchingValidityList.isLoading) return;
    searchValidityList(searchedValidity.value, currentPage);
  });

  const { current: searchValidityListDebounced } = React.useRef(
    useDebounce((description, page) => {
      validityList.setValue([]);
      searchValidityList(description, page);
    })
  );

  const handleClickEditTable = (item) => {
    setSelectedTable(item);
    navigate("atualizar");
  };

  const handleClickEditItem = (item) => {
    setSelectedTable(item);
    navigate("itens");
  };

  React.useEffect(() => {
    setSelectedTable(null);
    if (!validityList.value.length) {
      searchValidityList(searchedValidity.value, 0);
    }
  }, []); //eslint-disable-line

  return (
    <div className="container">
      <div className={styles.buttonNewTableContainer}>
        <LinkButton to="nova">Nova Tabela</LinkButton>
      </div>
      <div>
        <label htmlFor="table" className="label">
          Tabela de Vigência
        </label>
        <Input
          id="table"
          placeholder="Busque por uma tabela para filtrar"
          value={searchedValidity.value}
          onChange={({ currentTarget }) => {
            searchedValidity.setValue(currentTarget.value);
            searchValidityListDebounced(currentTarget.value, 0);
          }}
        />
      </div>
      <div className={styles.tablesContainer}>
        {validityList.value.length ? (
          <ul>
            {validityList.value.map((item, index) => (
              <li className={`${styles.tableItem}`} key={index}>
                <span
                  className={`${styles.tableItem__situationIndicator} ${item.tabela.ativo === "A" ? "active" : ""}`}
                  style={{ content: `${item.tabela.ativo === "A" ? "Ativo" : "Inativo"}` }}
                ></span>
                <div>
                  <p className={styles.tableItem__title}>{item.tabela.nome}</p>
                  <p className={styles.tableItem__date}>
                    Data Criação: {formatDate(item.tabela.dataCriacao, "dd/MM/yyyy")}
                  </p>
                </div>
                <span className={styles.tableItem__divisor}></span>
                <div className={styles.editButtonsContainer}>
                  <Button
                    variant="neutral"
                    className={styles.tableItem__button}
                    onClick={() => {
                      handleClickEditTable(item);
                    }}
                  >
                    Editar Tabela
                  </Button>
                  <Button
                    variant="neutral"
                    className={styles.tableItem__button}
                    onClick={() => {
                      handleClickEditItem(item);
                    }}
                  >
                    Editar Itens
                  </Button>
                </div>
              </li>
            ))}
            {!noMoreValidity && (
              <li className={`loadingContainer ${styles.searchingMoreValidityContainer}`}>
                <span className="loadingMessage" ref={loadMoreRef}>
                  Buscando mais tabelas
                </span>{" "}
                <Circle size={30} />
              </li>
            )}
          </ul>
        ) : searchingValidityList.isLoading ? (
          <div className={`loadingContainer`} style={{ height: "380px", justifyContent: "center" }}>
            <Circle size={100} />
            <span className="loadingMessage">Buscando Tabelas de Vigência</span>
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma tabela de vigência encontrada</p>
        )}
      </div>
    </div>
  );
}
