import React, { ReactNode } from "react";

import styles from "./CardApplication.module.css";

interface Props {
  icon?: ReactNode;
  name: string;
  fullName: string;
  link: string;
}

export default function CardApplication({ icon, name, fullName, link }: Props) {
  return (
    <div
      className={styles.card}
      onClick={() => {
        window.open(link, "_blank");
      }}
    >
      <figure className={styles.card__figure}>{icon || name.slice(0, 3).toUpperCase()}</figure>
      <p className={styles.card__name}>{name}</p>
      <span className={styles.card__fullName}>{fullName}</span>
    </div>
  );
}
