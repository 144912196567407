import React from "react";
import { Route, Routes } from "react-router-dom";
import useUserDevices from "./hooks/useUserDevices";
import List from "./List/List";
import InsertAndUpdate from "./InsertAndUpdate/InsertAndUpdate";

export default function UserDevices() {
  const data = useUserDevices();

  return (
    <Routes>
      <Route path="/" element={<List data={data} />} />
      <Route path="novo" element={<InsertAndUpdate data={data} />} />
      <Route path="atualizar" element={<InsertAndUpdate data={data} isUpdatePage />} />
    </Routes>
  );
}
