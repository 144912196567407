import React from "react";
import { Controller } from "react-hook-form";
import { Button, Input, Select } from "../../../../components/Form";
import useReleasedUnreleasedPremiationsReport from "./hooks/useReleasedUnreleasedPremiationsReport";

import styles from "./ReleasedUnreleasedPremiationsReport.module.css";
import dateUtils from "../../../../helpers/dateUtils";
import { Circle } from "../../../../components/Loading";

export default function ReleasedUnreleasedPremiationsReport() {
  const data = useReleasedUnreleasedPremiationsReport();

  const { form, searchCustomers, generateReleasedUnreleasedAwardsReport } = data;

  return (
    <div className="container" style={{ display: "flex", flexDirection: "column" }}>
      <div className={styles.formContainer}>
        <div className={styles.formContainer__customers}>
          <Controller
            name="customers"
            control={form.control}
            rules={{ minLength: { message: "Selecione ao menos um cliente", value: 1 } }}
            render={({ field, fieldState }) => (
              <>
                <label htmlFor="customers" className="label">
                  Clientes
                </label>
                <Select
                  {...field}
                  id="customers"
                  placeholder="Selecione os clientes"
                  error={fieldState.error?.message}
                  onInputChange={(text) => {
                    searchCustomers.fetchDebounced({
                      descricao: text,
                    });
                  }}
                  options={searchCustomers.data}
                  isLoading={searchCustomers.isFetching}
                  isDisabled={generateReleasedUnreleasedAwardsReport.isFetching}
                  isMulti
                />
              </>
            )}
          />
        </div>
        <div>
          <Controller
            name="initialDate"
            control={form.control}
            rules={{
              required: "Selecione a data inicial",
            }}
            render={({ field, fieldState }) => (
              <>
                <label htmlFor="initialDate" className="label">
                  Data Inicial
                </label>
                <Input
                  {...field}
                  id="initialDate"
                  type="date"
                  error={fieldState.error?.message}
                  disabled={generateReleasedUnreleasedAwardsReport.isFetching}
                />
              </>
            )}
          />
        </div>
        <div>
          <Controller
            name="finalDate"
            control={form.control}
            rules={{
              required: "Selecione a data final",
            }}
            render={({ field, fieldState }) => (
              <>
                <label htmlFor="finalDate" className="label">
                  Data Final
                </label>
                <Input
                  {...field}
                  id="finalDate"
                  type="date"
                  error={fieldState.error?.message}
                  disabled={generateReleasedUnreleasedAwardsReport.isFetching}
                />
              </>
            )}
          />
        </div>
        <Button
          className={styles.formContainer__button}
          onClick={() =>
            form.handleSubmit((data) => {
              generateReleasedUnreleasedAwardsReport.fetch({
                listaClientes: data.customers.map((item) => item.value.idCliente),
                dataInicial: dateUtils(data.initialDate).toStartDayISOString(),
                dataFinal: dateUtils(data.finalDate).toEndDayIsoString(),
              });
            })()
          }
          disabled={generateReleasedUnreleasedAwardsReport.isFetching}
        >
          Gerar Relatório
        </Button>
      </div>
      {generateReleasedUnreleasedAwardsReport.isFetching && (
        <div className="loadingContainer" style={{ flex: 1, justifyContent: "center" }}>
          <Circle size={100} />
          <p className="loadingMessage">Gerando Relatório</p>
        </div>
      )}
    </div>
  );
}
