import React from "react";

import styles from "./ApplicationsFrame.module.css";
import CardApplication from "./components/CardApplication";

export default function ApplicationsFrame() {
  return (
    <div className={styles.applicationsContainer}>
      <CardApplication
        name="Sig"
        fullName="Sistema Interno de Gestão de Vendas"
        link="https://app.sig.agrocp.agr.br/"
      />
      <CardApplication name="Apollo" fullName="Sistema de Chamados" link="https://apollo.agrocp.agr.br/" />
      <CardApplication
        name="Analytics"
        fullName="Sistema de Análise de Dados"
        link="https://app.analytics.agrocp.agr.br/"
      />
      <CardApplication name="Hermes" fullName="Sistema de Logística" link="https://app.hermes.agrocp.agr.br/" />
      <CardApplication name="Demeter" fullName="Sistema de Bags" link="https://app.demeter.agrocp.agr.br/" />
      <CardApplication
        name="Chronos Sync"
        fullName="Sistema de Pontos e Departamento Pessoal"
        link="https://app.chronossync.agrocp.agr.br/"
      />
      <CardApplication name="Portal Fábrica" fullName="Sistema da Fábrica" link="https://app.fabrica.agrocp.agr.br/" />
      <CardApplication name="RPA" fullName="Sistema de RPA" link="https://app.rpa.agrocp.agr.br/" />
    </div>
  );
}
